@import 'src/assets/style/utils/variables.module';
.overlay-button {
  background-color: transparent;
  border: none;
}
.offer-list-favorite {
  margin-top: 24px;

  &__title {
    display: flex;
    align-items: center;
    font-size: $fontSubtitleXs;
    font-weight: bold;

    img {
      padding-top: 1px;
    }
  }

  &__ico {
    width: 18px;
  }

  &__cards {
    display: flex;
    margin-top: 8px;
  }

  @media (min-width: $breakpoint-md) {
    &__title {
      font-size: 24px;
      font-weight: bold;
    }
    &__cards {
      margin-top: 0;
    }
  }

  .width-93 {
    width: 93%;
  }

  .mb-1 {
    margin-bottom: 1em;
  }

  .updated-UI-icon {
    width: 20px;
    height: 20px;
  }

  .offers-with-favorite-updated {
    margin-bottom: 2em;
  }
}
.favorite-list {
  .favorite-title {
    padding-left: 5px;
  }
  .favorite-icon {
    color: red;
  }
  .no-favorite-offer-text {
    margin-top: 0;
    padding: 0 5px;
  }
  .no-favorite-offer-text-title {
    margin: 0;
    font-weight: bold;
    padding: 0 5px;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.favorite-offers-updated {
  &__desktop {
    display: flex;
    flex-wrap: wrap;
    .favorite-item-wrapper {
      width: 32.5%;
    }
    .favorite-item-wrapper:nth-of-type(3n-1) {
      padding: 0 1.25%;
    }
  }
}
