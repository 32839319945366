@import 'src/assets/style/utils/variables.module';

.offer-drawer-new {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  .offer-drawer-new-img {
    height: 30%;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
  }
  .img-updated {
    height: 30vh;
  }
  .details-updated {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    height: 40vh !important;
  }
  .offer-drawer-new-details {
    padding: 1em;
    overflow-y: auto;
    min-height: 60%;
    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
    .description {
      .wmde-markdown {
        margin: 1em 0;
      }
    }
  }
  &__desktop {
    .offer-drawer-new-img {
      background-size: cover;
      background-position: center;
      position: relative;
      width: 88px;
      height: 50px;
      margin-left: 15px;
    }
    .offer-drawer-new-details {
      padding: 1em;
      overflow-y: auto;
      min-height: 60%;
      &a:link,
      & a:visited,
      a:link,
      a:visited {
        color: var(--link-color);
      }
    }
  }
  &__new-balance {
    color: #06599e;
    font-size: 16px;
  }

  &__timer {
    display: flex;
    margin-top: 18px;
    align-items: center;
    font-size: 10px;
    font-weight: 600;

    &__ico {
      margin-right: 4px;
    }

    &__txt {
      font-size: 10px;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__header {
      flex-direction: column;
      align-items: start;

      &__img {
        width: 127px;
        height: 69px;
        margin-bottom: 16px;
      }

      &__title {
        font-size: 24px;
      }
    }

    &__info {
      font-size: 16px;
    }

    &__new-balance {
      font-size: 16px;
    }

    &__timer {
      &__ico,
      &__ico.icon__ico {
        font-size: 14px;
      }
      &__txt {
        font-size: 14px;
      }
    }
  }
}
.h-50 {
  max-height: 50vh;
}
.offer-detail-new-drawer {
  .offer-drawer-new__btn {
    padding: 0px 15px;
    position: fixed;
    bottom: 25px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
  }
  .btn-updated {
    margin: 1.5em;
    margin-top: 0;
    .btn {
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
    }
  }
  .base-drawer__content__no-padding {
    margin-top: 0 !important;
    height: 100%;
    overflow-y: hidden;
  }
  .base-drawer__title__wrapper {
    padding: 18px 16px !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .offer-drawer-new__content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .offer-drawer-new__info {
    overflow-y: auto;
    font-size: 16px;
  }
  .text-bottom-bumper {
    display: inline-block;
    width: 100%;
    margin-top: 60px;
  }
  .offer-drawer-new__title {
    color: black;
    font-size: 18px;
    font-weight: 700;
  }
  .offer-drawer-new__subtitle {
    text-transform: uppercase;
    color: #4B5054;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  .offer-drawer-new__timer {
    margin-top: 10px;
  }
  .offer-detail-points-btn {
    margin-top: 1em;
    width: fit-content;
    border-radius: 1000px;
    padding: 6px 10px 4px 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--points-badge-text);
    min-width: 4em;
    text-align: center;
    min-height: 1.5em;
    background-color: var(--points-badge);
  }
  .locked-background {
    color: white;
    background-color: #677979;
  }
  &__desktop {
    .base-drawer__content {
      padding: 0px 30px 24px !important;
    }
    .offer-drawer-new__btn {
      position: absolute !important;
      bottom: 0;
      padding: 0px 30px;
      &__more {
        padding: 13px 10px;
        width: 84%;
        bottom: 0;
        text-align: center;
        margin: auto;
      }
    }
    .offer-drawer-new-details {
      height: 215px !important;
    }
  }
  .ptop-0 {
    padding-top: 0 !important;
  }
}
