@import 'src/assets/style/utils/variables.module';

.transaction-history-list {
  margin: 0 -16px;
  padding: 0 16px;
  overflow-x: auto;

  &--loading {
    &__item {
      height: 88px;
      margin: 10px 0;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin: 0 -80px;
    padding: 0 80px;

    &--loading {
      &__item {
        height: 108px;
      }
    }
  }
}

select {
  font-family: 'Lato';
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: black;
  background-color: white;
  min-height: 2rem;
  padding: 0.2rem 0.6rem;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../../../assets/images/arrow-down.png');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;

}

.noTransactions {
  text-align: center;
  vertical-align: middle;
  padding: 2em;
  font-family: 'Lato';
  font-weight: 600;
}

.base-drawer__content {
  position: relative;
}

.loading-indicator {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 -16px;
  overflow-x: hidden;
  background: #0000007a;

  .loading-dialog {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }

  .coin-wrapper {
    [role='button'] {
      position: absolute;
      top: calc(50% - 125px);
      left: calc(50% - 100px);
    }
  }
}
