@import "src/assets/style/utils/variables.module";

.invite-header {
  margin-bottom: 3em;

  &__img {
    width: 50%;
    height: 50%;
    display: block;
    margin: 30px auto;
  }

  &__title {
    margin: auto;
    text-align: center;
    font-size: $fontLargeTitleXs;
    font-weight: 800;
    line-height: 32px;
  }

  &__instruction {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.44px;
    line-height: 20px;
    white-space: pre-line

    p {
      font-size: 14px;
    }

    a {
      color: var(--link-color);
    }
  }

  @media (min-width: $breakpoint-md) {
    &__img {
      height: 155px;
      margin-top: 70px;
    }

    &__title {
      line-height: 28px;
    }

    &__instruction {
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
}

.highlight-header{
  &__img{
    vertical-align: middle;
  }
}