@import "src/assets/style/utils/variables.module";

.sold-out__bg {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0.5;
  cursor: not-allowed;
}

.sold-out__label {
  position: absolute;
  left: 8px;
  top: 8px;
  right: initial;
  padding: 2px 8px;
  background: #F8A889;
  border-radius: 1000px;
  font-size: 10px;
  font-weight: bold;
  color: #A71E24;
  cursor: not-allowed;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

@media (min-width: $breakpoint-md) {
  .sold-out__label {
    font-size: 14px;
  }
}