.redemption-error-dialog {
  .info-icon {
    margin-right: auto;
    margin-left: auto;
    margin-top: 1em;
    font-size: 3rem;
  }
  .title {
    text-align: center;

    .MuiTypography-h6 {
      font-family: 'Lato';
      font-size: 14px;
      font-weight: 300 !important;
    }
  }
  .dialog-btn {
    height: 4.5em;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 2em;
    margin-top: 0.5em;
  }
}
.redemption-error-new {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  .redemption-error-new-img {
    height: 30%;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
  }
  .img-updated {
    height: 45% !important;
  }
  .redemption-error-new-details {
    padding: 1em;
    overflow: scroll;
    min-height: 60%;
    text-align: center;
    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
    .info-icon {
      font-size: 46.67px;
    }
    .title {
      color: #647588;
      font-family: 'Lato';
      font-size: 14px;
      font-weight: 400 !important;
      margin-top: 10px;
    }
  }
  .details-updated {
    min-height: 35% !important;
    margin-bottom: -2em;
  }
  .redemption-error-new__btn {
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    bottom: 25px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
  }
  .btn-updated {
    position: inherit;
    padding: 0;
  }
}
.redemption-error-updated {
  height: 40vh;
}
