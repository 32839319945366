@import 'src/assets/style/utils/variables.module';
.content-wrapper:has(.terms) {
  background-color: #edf2f4;
}
.terms {
  margin: auto;
  min-height: 96vh;

  &__btn-container {
    margin-inline: auto;
    width: 22em;
  }

  &__switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    width: 22em;
    margin-inline: auto;
    &__icon {
      border-radius: 100%;
      background: white;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin-right: 8px;
    }
    &__iconimg {
      margin: auto;
    }
    &__text {
      width: 70%;
    }
    &__title {
      font-weight: 700;
      font-size: 12px;
      margin: 0;
    }
    &__description {
      font-size: 10px;
      color: #3E5656;
      margin: 0;
    }
    &__toggle {
      font-size: $fontTextXs;
      width: 15%;
    }
  }

  &__links {
    margin-top: 15px;
    font-size: $fontTextXs;
    text-align: center;
    line-height: 16px;
  }

  @media (min-width: $breakpoint-md) {
    width: 411px;
    min-height: 84vh;

    &__switcher {
      margin-bottom: 33px;
      font-size: 16px;

      &__text {
        margin-left: 10px;
      }
    }

    &__links {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
