.password-reset-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  box-sizing: border-box;

  .password-reset-left {
    width: 50vb;
    flex-grow: 1;
    background-color: #edf2f4;
    display: flex;
    justify-content: center;
    @media (min-width: 1024px) {
      align-items: center;
    }

    .form-field-box {
      height: 40em;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 1024px) {
        max-width: 25em;
      }
      @media (max-width: 1023px) , (max-height: 680px) {
        width: 90%;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .tenant-logo {
        margin-bottom: 5em;
        img {
          max-width: 15em;
          height: 4.5em;
        }
      }

      .back-to-sign-in {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        margin-bottom: 2em;
        margin-top: 6em;
        .MuiTypography-root {
          font-family: 'Lato';
          font-size: 14px;
        }
      }

      .password-reset-heading {
        font-family: 'Lato';
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0.75em;
        color: #000000;
        @media (max-width: 1023px), (max-height: 680px) {
          text-align: center;
          font-size: 24px;
          margin-bottom: 1em;
        }
      }

      .password-reset-text {
        font-family: 'Lato';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2em;
        @media (max-width: 1023px), (max-height: 680px) {
          text-align: center;
        }
      }

      .MuiFilledInput-root {
        background-color: white !important;
        border: 2px solid #bfc7cd !important;
        border-radius: 8px;
      }

      .MuiFilledInput-underline::before {
        visibility: hidden;
      }

      .MuiFilledInput-underline::after {
        visibility: hidden;
      }

      .responsive-btn {
        @media (max-width: 1023px), (max-height: 680px) {
          margin-bottom: 2em;
        }
        @media (max-height: 390px) {
          margin-bottom: 0;
          flex-grow: 1;
        }
      }
    }
    .password-reset-form {
      width: auto !important;
      @media (max-width: 1023px), (max-height: 680px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .password-reset-right {
    flex-grow: 1;
    width: 50vb;
    display: flex;
    align-items: center;
    .steps-box {
      margin-right: auto;
      margin-left: auto;
      width: 20em;
      padding: 4em 5em;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.1)
      );
      backdrop-filter: blur(40px);
      -webkit-backdrop-filter: blur(40px);
      border-radius: 20px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
      .step {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;
        .step-header {
          display: flex;
          flex-direction: row;
          gap: 0.2em;
          align-items: center;
          .MuiTypography-root {
            font-family: 'Lato';
            font-size: 16px;
            font-weight: 700;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        .MuiTypography-root.description {
          font-family: 'Lato';
          font-size: 12px;
          ul {
            padding-inline-start: 20px;
            padding-inline-start: 22px;
          }
        }
      }
    }
  }

  .lato,
  .MuiInputBase-root {
    font-family: 'Lato' !important;
  }

  .MuiInputBase-input {
    font-size: 16px;
  }

  .label {
    color: #647588;
  }

  .no-decor {
    text-decoration: none;
  }

  .disabled {
    background-color: var(--disable) !important;
    color: var(--disable-text) !important;
  }

  .MuiFormHelperText-root.Mui-error {
    color: var(--error);
  }
}
