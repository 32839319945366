@import "src/assets/style/utils/variables.module";
@import "src/assets/style/utils/mixins";

.offer__list__prompt {
  &--ms {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 64px;
    background: #06599E;
    color: #FFFFFF;

    &__icon {
      margin-left: 20px;
    }

    &__btn {
      font-size: 12px;
      border-bottom: 0.5px solid white;
      margin-left: 8px;
      margin-right: 16px;
    }

    &__text {
      font-size: 14px;
      margin-left: 12px;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__circle {
      margin-right: 15px;
    }
    &__text {
      &--md {
        color: black;
        font-size: 24px;
        font-weight: bold;
      }
    }
    &__ico {
      font-size: 26px;
    }
  }
}