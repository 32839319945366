@import 'src/assets/style/utils/variables.module';

.redemption-drawer-ticket {
  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
    text-align: center;
  }
  &__instruction {
    font-size: $fontTextXs;
    margin-top: 8px;
    line-height: 20px;
    color: #647588;

    a:link,
    a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
  }
  &__icon {
    display: block;
    margin: auto;
    font-size: 45px;
    color: #3A811E;
  }
  @media (min-width: $breakpoint-md) {
    &__instruction {
      font-size: 18px;
    }
    &__icon {
      font-size: 56px;
    }
  }
}

.redemption-drawer-new-ticket {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  .redemption-drawer-new-ticket-img {
    min-height: 28vh !important;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
  }
  .img-updated {
    min-height: 20vh;
  }
  .redemption-drawer-new-ticket-details {
    padding: 1em;
    overflow: scroll;
    height: 60vh;
    text-align: center;
    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
    .redemption-drawer-new-ticket__icon {
      font-size: 46.67px;
      color:#3A811E
    }
    .redemption-drawer-new-ticket__title {
      margin-top: 15px;
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
    .redemption-drawer-new-ticket__instruction {
      color: #647588;
      font-family: 'Lato';
      font-size: 14px;
      font-weight: 400 !important;
      margin-top: 5px;
    }
  }
  .redemption-drawer-new-ticket__btn {
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    bottom: 25px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.ticket-drawer-updated {
  max-height: 70vh !important;
  overflow: hidden;
  .btn-updated {
    padding: 0 !important;
    position: inherit !important;
    width: 100% !important;
    margin: auto;
    margin-bottom: 2em !important;
  }
  .details-updated {
    overflow: auto;
    max-height: 20vh !important;
    margin-top: 1em;
  }
  .padding-updated {
    padding: 31px 25px !important;
    padding-top: 0 !important;
  }
  .w-90 {
    width: 90% !important;
  }
  .img-updated {
    min-height: 25vh !important;
  }
}
