@import "src/assets/style/utils/variables.module";

.provider-result-dialog {
  overflow: hidden;

  &__spinner {
    max-width: 100px;
    max-height: 100px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 320px;
    max-height: 243px;

    &__spinner {
      max-width: 100px;
      max-height: 100px;
    }
  }
}