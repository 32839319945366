@import "src/assets/style/utils/variables.module";

.offer-drawer {
  overflow-x: auto;

  &__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__img {
      width: 83px;
      height: 45px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__title {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__info {
    margin-top: 18px;
    color: #647588 !important;
    font-size: 12px;

    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
  }

  &__new-balance {
    color:  #06599E;
    font-size: 12px;
  }

  &__timer {
    display: flex;
    margin-top: 18px;
    align-items: center;
    font-size: 10px;
    font-weight: 600;

    &__ico {
      margin-right: 4px;
    }

    &__txt {
      font-size: 10px;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__header {
      flex-direction: column;
      align-items: start;

      &__img {
        width: 127px;
        height: 69px;
        margin-bottom: 16px;
      }

      &__title {
        font-size: 24px;
      }
    }

    &__info {
      font-size: 16px;
    }

    &__new-balance {
      font-size: 16px;
    }

    &__timer {
      &__ico,
      &__ico.icon__ico {
        font-size: 14px;
      }
      &__txt {
        font-size: 14px;
      }
    }
  }
}