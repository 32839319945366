@import "src/assets/style/utils/variables.module";

.redemption-result {
  margin: auto;
  overflow-y: auto;

  &__spinner {
    max-height: none;
    max-width: none;

    &--active {
      max-height: none;
      max-width: none;
      margin: 50px auto;
      padding: 10px;
      background: #E1E7EC;
      border-radius: 15px;
    }
  }

  &__content {
    width: auto;
    height: auto;
    max-width: 343px;
    max-height: 550px;
    transition: width, height, max-width, max-height;
    transition-duration: .55s;
    transition-timing-function: ease-in-out;
    overflow: hidden;

    &--hidden {
      max-width: 100px;
      max-height: 100px;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__content {
      max-width: none;
      max-height: none;
      transition: transform, opacity;
      transition-duration: .2s;
      transition-timing-function: linear;

      &--hidden {
        opacity: 0;
        transform: translate(700px, 0);
      }

    }
  }
  .custom-padding {
    padding: 31px 25px;
    padding-top: 15px;
  }
  .custom-padding-updated {
    padding: 31px 25px;
    padding-top: 0;
  }
}