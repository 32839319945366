.offer-terms {
  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    text-align: center;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #647588;
    text-align: center;
  }
}
.terms-and-condition-updated {
  .offer-terms__title {
    text-align: left;
    padding: 0 15px;
    font-size: 18px;
  }
  .mt-2 {
    margin-top: 2em;
  }
  .offer-terms__description {
    text-align: left;
    padding: 0 15px;
    font-size: 16px;
  }
  .mb-3 {
    margin-bottom: 3em;
  }
}
.maxh-70 {
  max-height: 70vh !important;
}
.p-1 {
  padding: 1em;
}