@import "src/assets/style/utils/variables.module";

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 75px;

  &__text {
    text-align: center;
    &__title{
      font-weight: bold;
      margin-top: 48px;
      font-size: $fontLargeTitleXs;
      text-transform: uppercase;
    }
    &__description{
      margin: 8px 56px 0;
      font-size: 14px;
    }
  }

  &__image{
    display: flex;
    justify-content: center;
    margin-top: 96px;
  }
  &__button{
    margin-top: 87px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 411px;
    margin: auto;

    &__image{
      margin-top: 219px;
    }
    &__button{
      margin-top: 80px;
    }

    &__text {
      &__title{
        margin-top: 56px;
      }
      &__description{
        margin-top: 12px;
        font-size: 18px;
      }
    }
  }
}

.link-button {
  margin-top: 75px;
  width: 100% !important;
  padding: 0px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.callus_txt {
  color: black;
  font-size: 14px;
  font-weight: bold;
  z-index: 10000;
  padding-left: 12.5px;
}

.phone_txt {
  border-spacing: 0px;
  padding: 0 !important;
  margin-left: -16px;
  margin-right: -15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
