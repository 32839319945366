@import "src/assets/style/utils/variables.module";

.provider-success-dialog {
  &__ico {
    font-size: 33px;
    color: var(--secondary);
    text-align: center;
  }

  &__msg {
    margin-top: 20px;
    padding: 0 35px;
    font-size: $fontTextXs;
    text-align: center;
    letter-spacing: 0.38px;
    color: var(--primary);
  }

  &__btn {
    margin-top: 15px;
  }

  @media (min-width: $breakpoint-md) {
    &__ico {
      font-size: 52px;
    }

    &__msg {
      padding: 0;
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    &__btn {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
}