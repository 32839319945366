.form-control {
  width: 100%;

  .MuiInputBase-input,
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #647588;
  }

  .MuiInputBase-input {
    text-align: left;
  }
}

.form-control-error {
  width: 100%;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #E45858;
  }

  .MuiInputBase-input {
    text-align: left;
  }
}

.form-control-label {
  display: inline-block;
  margin-bottom: 10px;
}