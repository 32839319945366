.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5em;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000007;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.03);
  margin-bottom: 0.5em;
  height: 4.5em;

  .left {
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 15px;
    padding: 4px 12px 4px 12px;
    min-width: 3em;
    background-color: rgba(237, 242, 244, 1);
    min-height: 2em;
  }

  .right {
    display: flex;
    flex-direction: column;
    .top {
      width: 5em;
      height: 1.5em;
      background-color: rgba(237, 242, 244, 1);
      border-radius: 0 !important;
      margin-bottom: -.25em;
    }

    .bottom {
      width: 10em;
      height: 2em;
      background-color: rgba(237, 242, 244, 1);
      border-radius: 0 !important;
    }
  }
}
