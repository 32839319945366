@import 'src/assets/style/utils/variables.module';

.new-feature-dialog {
  .MuiDialog-paper {
    border-radius: 24px;
    width: 88%;
    margin: 32px auto;
    @media (min-width: $breakpoint-md) {
      width: 27% !important;
      min-width: 320px;
      max-width: 400px;
    }
  }
  .cancel-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 1000;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .new-feature-content {
    display: flex;
    padding: 0 0 8px 0 !important;
    flex-direction: column;
    .step-swipe-box {
      flex: 1;
      .react-swipeable-view-container {
        height: 100% !important;
      }
      .step-info {
        display: flex;
        flex-direction: column;
        height: 95%;
        .step-img {
          flex: 2;
          max-height: 50%;
        }
        .step-detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0.75em 1em 0 1em;
          align-items: center;
          overflow: auto;
          .step-label {
            color: #979797;
            font-size: 14px;
            margin-bottom: 0.25em;
            font-weight: 700;
            text-align: center;
            @media (min-width: $breakpoint-md) {
              margin-bottom: 0.35em;
            }
          }
          .step-title {
            font-size: 18px;
            margin-bottom: 0.25em;
            font-weight: 700;
            text-align: center;
            @media (min-width: $breakpoint-md) {
              margin-bottom: 0.35em;
            }
          }
          .step-description {
            font-size: 14px;
            flex: 1;
            p {
              margin-block-start: 0;
              margin-block-end: 0;
            }
          }
        }
        .p-top {
          padding-top: 0.75em !important;
        }
      }
    }
    .cta {
      width: 90%;
      margin: auto;
      margin-bottom: 0.5em !important;
      padding: 10px 10px;
    }
    .m-top {
      margin-top: 1em;
    }
    .MuiMobileStepper-root {
      .MuiMobileStepper-dots {
        width: 100% !important;
        justify-content: center !important;
        .MuiMobileStepper-dot {
          width: 11px;
          height: 11px;
          background-color: #d9d9d9;
          margin: 0 4px;
        }
        .MuiMobileStepper-dotActive {
          background-color: var(--btn-primary);
        }
      }
    }
    .pagination-dots {
      margin-top: auto;
      background-color: transparent !important;
    }
  }
}
