.tooltip-container-step-one {
  margin-top: 15px;
  width: 85vw;
  margin-left: 7.5%;
  height: auto;
  background-color: black;
  border-radius: 4px;
  position: relative;
  color: white;
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 0px 16px;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .content {
    padding: 16px;
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip-container-step-one:after {
  content: ' ';
  width: 2px;
  height: 0px;
  border-top: 15px solid black;
  border-left: 10px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 30px;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
  @media (max-width: 350px) {
    left: 20px;
  }
  @media (max-width: 380px) {
    left: 25px;
  }
  @media (min-width: 460px) {
    left: 40px;
  }
  @media (min-width: 520px) {
    left: 55px;
  }
  @media (min-width: 600px) {
    left: 65px;
  }
  @media (min-width: 680px) {
    left: 75px;
  }
}

.tooltip-container-step-three {
  margin-top: 15px;
  width: 96vw;
  height: auto;
  border-radius: 4px;
  position: relative;
  color: white;
  .steps-wrapper {
    width: 70%;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    opacity: 100 !important;
  }
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 0px 16px;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .content {
    padding: 16px;
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip-container-step-three:after {
  content: ' ';
  width: 2px;
  height: 0px;
  border-top: 15px solid black;
  border-left: 10px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 50%;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
}

.tooltip-container-step-other {
  margin-top: 17px;
  height: auto;
  border-radius: 4px;
  position: relative;
  color: white;
  width: 96vw;
  .steps-wrapper {
    width: 70%;
    background-color: black;
    margin-left: 30%;
    border-radius: 4px;
    opacity: 100 !important;
  }
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 0px 16px;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .content {
    padding: 16px;
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip-container-step-other:after {
  content: ' ';
  width: 2px;
  height: 0px;
  border-top: 15px solid black;
  border-left: 10px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  right: 23px;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
  @media (min-width: 500px) {
    right: 15px;
  }
}

.tooltip-container-step-four {
  width: 96vw;
  .steps-wrapper {
    margin-left: 20%;
    width: 70%;
  }
}

.tooltip-container-step-four:after {
  right: 55px;
  @media (min-width: 420px) {
    right: 65px;
  }
  @media (min-width: 500px) {
    right: 75px;
  }
  @media (min-width: 580px) {
    right: 85px;
  }
  @media (min-width: 680px) {
    right: 130px;
  }
}

.bottom-block-updated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1em 1.5em 1em;
  font-size: 14px;
  gap: 0.5em;
  .placeholder-button {
    color: #ffffff !important;
    border: 0px solid black !important;
    border-radius: 8px;
    height: 30px;
    width: 20%;
  }
  .progress-count {
    color: #979797;
  }
  .next-button {
    text-align: center;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 40%;
  }
  .previous-button {
    color: #979797;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    max-width: 40%;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    .MuiButton-label {
      text-transform: none;
    }
  }
}

.close-updated {
  color: #979797;
  width: 20px;
  height: 20px;
}
