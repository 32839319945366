@import "src/assets/style/utils/variables.module";

.connect-account {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $fontSubtitleXs;
    font-weight: bold;
  }

  &__provider-list {
    display: flex;
    flex-direction: column;
    margin-top: 17px;
  }

  &--loading-title {
    width: 27%;
    min-height: 15px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 704px;
    margin: auto;
    &__title {
      font-size: 24px;
    }
    &--loading-title {
      min-height: 22px;
    }
  }
}

