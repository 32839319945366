@import 'src/assets/style/utils/variables.module';

.multi-language-drawer {
  text-align: center;
  padding: 0;
  margin-top: 10px !important;
}
.multi-language-wrapper {
  max-height: 330px;
  overflow-y: auto;
  .language-option {
    width: 100% !important;
    display: block;
    &:focus-visible {
      border: 1px solid;
    }
    span {
      font-size: 14px;
    }
    position: relative;
    input[type='checkbox'] {
      display: none;
    }
    .check-icon {
      position: absolute;
      right: 0;
      top: 0px;
    }
    .check-icon-hide {
      display: none;
    }
    .check-icon-show {
      display: block;
    }
  }
  .multi-language-list-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .selected {
    background-color: #edf2f4 !important;
    font-weight: 700;
  }
}

.save-button {
  margin-top: 15px;
  width: 90% !important;
  margin-bottom: 25px;
}
.save-button--disabled {
  color: var(--disable-text) !important;
  background-color: var(--disable) !important;
}
.save-button--abled {
  color: var(--btn-text);
  background-color: var(--btn-primary);
}
.multi-lang-selection {
  height: 80% !important;
  overflow: auto;
}
.multi-lang-save-btn {
  height: 20% !important;
}
.lang-drop {
  .dropdown {
    min-width: 193px !important;
    margin-left: -1%;
    .check-icon {
      position: absolute;
      top: 6px;
      right: 15px;
    }
    .hide-icon {
      display: none;
    }
    .show-icon {
      display: inline-block;
    }
    .lang-menu-item {
      border-radius: 8px;
      margin: 0px 8px;
      font-weight: 400;
    }
    .selected-lang {
      background-color: #e1e7ec;
      pointer-events: none;
      font-weight: 700;
    }
  }
}
