@import "src/assets/style/utils/variables.module";

.phone-verification-already-used {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    margin: 0 0 .8em;
  }

  &__title {
    font-size: $fontTextXs;
    text-align: center;
    font-weight: 700;
  }

  &__message {
    font-size: $fontTextXs;
    text-align: center;
    margin-bottom: .2em;
  }

  &__btn {
    margin-top: 16px;
    background-color: var(--btn-primary);
    color: var(--btn-text);;
    width: 219px;
    height: 45px;
    text-transform: none;

    &:hover {
      background-color: var(--btn-hover);
    }
  }

  @media (min-width: $breakpoint-md) {
    &__title {
      font-size: 16px;
    }
  }
}

.terms-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--link-color);
  text-decoration: underline;
  padding-top: 1em;
}


.loading-dialog {
  max-height: none;
}