@import "src/assets/style/utils/variables.module";

.refer-header {
  margin-bottom: 50px;
  
  &__img {
    width: 50%;
    height: 50%;
    display: block;
    margin: 30px auto;
  }

  &__title {
    margin: auto;
    text-align: center;
    font-size: $fontLargeTitleXs;
    font-weight: 700;
    line-height: 32px;
  }

  &__instruction {
    width: 75%;
    margin: auto;
    margin-top: 8px !important;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.44px;
    line-height: 20px;
    white-space: pre-line

    p {
      font-size: 14px;
    }

    a {
      color: var(--link-color);
    }
  }

  &__howto{
    text-align: center;
    margin-top: 10px;
    text-decoration: underline;
    text-underline-offset: 3px;
    color: #06599E !important;
    text-decoration-color: #06599E !important;

  }
  @media (min-width: $breakpoint-md) {
    width: 100%;
    margin-bottom: 0px;
    &__img {
      margin-left: 0;
      width: 90%;
      height: 70%;
    }

    &__title {
      line-height: 28px;
      margin-top: 2.5em;
      text-align: start;
      font-size: 20px;
    }

    &__instruction {
      font-size: 16px;
      letter-spacing: 0.5px;
      width: 100%;
      text-align: start;
    }
  }
}

.highlight-header{
  &__img{
    vertical-align: middle;
  }
}