.tooltip-title {
  height: 48px;
  width: 100%;
  padding-top: 15px;
  padding-left: 16px;
  padding-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}

.tooltip-content {
  margin: 0px;
  text-align: center;
  height: 40px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #edf2f4;
  border-bottom: 1px solid #edf2f4;
}

.tooltip_container_top {
  margin-top: 10px;
  width: 96vw;
  height: auto;
  background-color: white;
  border-radius: 16px;
  position: relative;
  color: #313333;
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #edf2f4;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .mt-10 {
    padding: 16px;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip_container_top:after {
  content: ' ';
  width: 0px;
  height: 0px;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 30px;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
}

.tooltip_container_top_start {
  margin-top: 10px;
  width: 96vw;
  height: auto;
  background-color: white;
  border-radius: 16px;
  position: relative;
  color: #313333;
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #edf2f4;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .mt-10 {
    padding: 16px;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip_container_top_start:after {
  content: ' ';
  width: 0px;
  height: 0px;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 49%;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
}

.tooltip_container_bottom {
  margin-bottom: 9px;
  width: 96vw;
  height: auto;
  background-color: white;
  border-radius: 16px;
  position: relative;
  color: #313333;
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #edf2f4;
    .title {
      margin: 0;
      padding: 0;
      font-size: 1em;
    }
  }
  .mt-10 {
    padding: 16px;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }
  .mt-11 {
    padding-left: 16px;
    color: black;
    font-size: 13px;
    font-weight: 400;
    .icon-bundle {
      line-height: 3px;
    }
    margin-bottom: 5px;
    .upper-word {
      font-size: 13px;
    }
  }
  .icon-bundle {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-size: 10px;
    }
  }
}

.tooltip_container_bottom:after {
  content: ' ';
  width: 0px;
  height: 0px;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 30px;
  bottom: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(0deg);
}

.tooltip_container_top_end {
  margin-top: 9px;
  width: 96vw;
  height: auto;
  background-color: white;
  border-radius: 16px;
  position: relative;
  color: #313333;
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #edf2f4;
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .mt-10 {
    padding: 16px;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip_container_top_end:after {
  content: ' ';
  width: 0px;
  height: 0px;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  right: 30px;
  top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  transform: rotate(180deg);
}

.hideBackButton {
  display: inline-flex;
  background-color: white;
  color: #ffffff !important;
  border: 0px solid white !important;
  border-radius: 8px;
  width: 104px;
  height: 40px;
}

.bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 16px;
  border-top: 1px solid #edf2f4;
  .progressCount {
    color: #647588;
  }

  .nextButton {
    border-radius: 8px;
    background-color: var(--btn-primary);
    width: 104px;
    height: 40px;
    color: #fff;
    text-transform: none;
    font-size: 14px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .nextButton:focus {
    outline: none !important;
    outline-offset: none !important;
  }

  .closeButton {
    text-transform: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .closeButton:focus {
    outline: none !important;
    outline-offset: none !important;
  }

  .tooltip-previous {
    display: inline-flex;
    background-color: white;
    color: #ffffff !important;
    border: 0px solid white !important;
    border-radius: 8px;
    width: 104px;
    height: 40px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .tooltip-previous:focus {
    outline: none !important;
    outline-offset: none !important;
  }

  .mui-previous-tour-button {
    display: inline-flex;
    background-color: white;
    color: #647588;
    border: 1px solid #647588;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    width: 104px;
    height: 40px;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .MuiButton-label {
      text-transform: none;
    }
  }
  .mui-previous-tour-button:focus {
    outline: none !important;
    outline-offset: none !important;
  }
}

.close-button {
  background-color: transparent;
  border: none;
}