:root {
  --primary: #191919;
  --primary-text: white;
  --secondary: #3A811E;
  --secondary-text: #191919;
  --accesory: #E1E7EC;
  --link-color: #0081AD;

  --disable: #BFC7CD;
  --disable-text: #45545F;

  --error: #E45858;

  /* Header */
  --header: #E1E7EC;
  --header-text: #191919;

  /* PointsBadge */
  --points-badge: #0081AD;
  --points-badge-text: #191919;

  /* Icons */
  --icons: #191919;

  /* Button */
  --btn-primary: #006BA6;
  --btn-text: white;
  --btn-hover: #1A7AAF;
  --btn-active: #000;
}