@import "src/assets/style/utils/variables.module";

.loading-dialog {
  width: auto;
  height: auto;
  max-width: 220px;
  max-height: 160px;
  transition: width, height, max-width, max-height;
  transition-duration: .55s;
  transition-timing-function: linear;
  overflow: hidden;

  &__spinner {
    max-width: 100px;
    max-height: 100px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 320px;
    max-height: 223px;

    &__spinner {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
