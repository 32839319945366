.invite-code-form-mob {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  margin-top: 4em;
  .language-selector {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: .5em;
    align-items: center;
    .lang-icon {
      width: 1.15em;
      height: 1.15em;
    }
    .current-lang {
      font-size: 15px;
      font-weight: 700;
    }
    .change-lang {
      font-size: 15px;
      color: #855703;
      text-decoration: underline;
    }
  }
  .back-to-sign-in {
    color: black;
  }
  .form-steps-box {
    .welcome-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1em;
      .MuiTypography-root {
        font-family: 'Lato';
        font-size: 24px;
        font-weight: 700;
        color: black;
      }
      img {
        width: 180px;
        height: 140px;
      }
    }
    .welcome-steps-box {
      margin: 1em 5%;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      .step-info {
        .step-header {
          display: flex;
          flex-direction: row;
          background-color: white;
          border-radius: 8px;
          padding: 0.5em 2em 0.5em 2em;
          gap: 1em;
          align-items: center;
          img {
            width: 56px;
            height: 56px;
          }
          .title {
            font-family: 'Lato';
            font-size: 14px;
            color: black;
            font-weight: 700;
          }
          .description {
            font-family: 'Lato';
            font-size: 10px;
            font-weight: 400;
            color: #3E5656;
          }
        }
      }
    }
  }
  form {
    .invite-code-field {
      margin-bottom: 1em;
      width: 100% !important;
    }
    .opt-invite-code-toggle {
        margin-bottom: 1em;
    }
  }
  .sign-up-actions {
    margin-top: 1em;
    text-align: center;
    margin-bottom: 1em;

    .MuiTypography-root {
      font-family: 'Lato';
      font-size: 12px;
      font-weight: 400;
    }
    .text {
      color: #647588;
    }
  }
}

.mobile-view-box {
  .MuiFilledInput-root {
    background-color: white !important;
    border: 2px solid #bfc7cd !important;
    border-radius: 8px;
  }
  .MuiFilledInput-input {
    padding: 25px 12px 10px;
  }
  .MuiFilledInput-underline::before {
    visibility: hidden;
  }
  .MuiFilledInput-underline::after {
    visibility: hidden;
  }
  .MuiInputBase-root {
    font-family: 'Lato' !important;
  }
  .MuiInputBase-input {
    font-size: 16px;
    font-family: 'Lato' !important;
    font-weight: 700;
  }
  .label {
    color: #647588;
  }
  .disabled {
    background-color: var(--disable) !important;
    color: var(--disable-text) !important;
  }
}
