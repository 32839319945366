@import "src/assets/style/utils/variables.module";

.icon {
  &__circle {
    padding: 10px;
    background: var(--icons);
    color: var(--primary-text);
    border-radius: 50%;

    &:hover {
      background: lighter(var(--icons), 0.2);
    }

    &__no-hover:hover {
      background: var(--icons);
    }
  }
  &__ico {
    font-size: 16px;
  }
  &__shadow {
    box-shadow: 0 0 5px var(--icons);
  }
  @media (min-width: $breakpoint-md) {
    &__ico {
      font-size: 26px;
    }
  }
}