.content-wrapper {
  background: white;
  padding: $main-content-padding-xs;
  box-shadow: none;

  @media (min-width: $breakpoint-md) {
    max-width: $main-content-max-width;
    margin: auto;
    padding: 50px $main-content-padding-md;
  }
}
