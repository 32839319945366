@font-face {
  font-family: Lato;
  font-weight: 900;
  src: url('../../font/lato/lato-black.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 800;
  src: url('../../font/lato/lato-heavy.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 700;
  src: url('../../font/lato/lato-bold.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 600;
  src: url('../../font/lato/lato-semibold.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 500;
  src: url('../../font/lato/lato-medium.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url('../../font/lato/lato-regular.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 300;
  src: url('../../font/lato/lato-light.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 200;
  src: url('../../font/lato/lato-thin.ttf') format("opentype");;
}
@font-face {
  font-family: Lato;
  font-weight: 100;
  src: url('../../font/lato/lato-thin.ttf') format("opentype");;
}