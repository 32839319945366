@import "src/assets/style/utils/variables.module";

.error-connect-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--error);
  box-shadow: none;
  height: 33px;
  padding: 15px 13px;
  font-size: 14px;
  font-weight: bold;

  &__image {
    margin-right: 16px;
  }

  &__btn {
    width: 50px;
    color: white;
    cursor: pointer;
  }

  &__description {
    display: flex;
    align-items: center;
    color: #E1E7EC;
  }


  @media (min-width: $breakpoint-md) {
    justify-content: flex-end;
    font-size: 16px;

    &__description {
      margin-right: 32px;
    }

    &__btn {
      margin-right: 160px;
    }
  }
}
