@import "src/assets/style/utils/variables.module";

.btn,
.btn__small {
  font-size: $fontTextXs;
}

.btn {
  width: 100%;
  padding: 13px 10px;
  color: var(--btn-text);
  background: var(--btn-primary);
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: bold;
  border-radius: 8px;

  &__small{
    padding: 8px;
  }

  &-disable{
    background: var(--disable);
    color: var(--disable-text);
    cursor: not-allowed;

    &.MuiButton-root,
    &.Mui-disabled {
      background: var(--disable);
      color: var(--disable-text);
    }
  }

  &:hover {
    background: var(--btn-primary) !important;
  }

  &:active {
    background: var(--btn-primary);
  }


  @media (min-width: $breakpoint-md) {
    &:hover {
      background: var(--btn-hover) !important;
    }
    font-size: 16px;
  }
}
