.password-reset-email-dialog {
    .MuiDialog-paper {
      border-radius: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }
    .check-icon {
      margin-right: auto;
      margin-left: auto;
      margin-top: 1em;
      font-size: 3rem;
      color: #09BA70;
    }
    .title {
      text-align: center;
  
      .MuiTypography-h6 {
        font-family: 'Lato';
        font-weight: 14px;
        font-weight: 700 !important;
      }
    }
    .content {
      text-align: center;
      color: #647588;
      padding: 4px 14px;
    }
    .dialog-btn {
      height: 3em;
      margin-right: 1em;
      margin-left: 1em;
      margin-bottom: 2em;
      margin-top: 0.5em;
    }
    .close {
      position: absolute;
      left: 1em;
      top: 1em;
      color: #858585;
      cursor: pointer;
      margin-bottom: 1em;
    }
  }
  