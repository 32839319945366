@import 'src/assets/style/utils/variables.module';

.offer__list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 5px);
  margin: 12px 2.5px 0;
  text-align: left;
  cursor: pointer;

  &:nth-child(odd) {
    margin-right: 5px;
  }
  &:nth-child(even) {
    margin-left: 5px;
  }
  &__preview {
    display: flex;
    position: relative;
    height: 109px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &__img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  &__amount,
  &__amount--success {
    margin-top: 8px;
    font-size: $fontSmallTextXs;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__amount {
    color: #bfc7cd;

    &--success {
      color: var(--points-badge);
    }
  }
  &__title {
    margin: 4px 0;
    font-size: $fontTextXs;
    font-weight: 600;
  }

  @media (min-width: $breakpoint-md) {
    flex-basis: 265px;
    margin: 15px 10px;

    &:nth-child(odd) {
      margin: 15px 9px;
    }
    &:nth-child(even) {
      margin: 15px 9px;
    }
    &__preview {
      width: 265px;
      height: 144px;

      &__img:hover {
        transform: scale(1.3);
      }
    }
    &__title {
      font-size: 20px;
    }
    &__amount,
    &__amount--success {
      margin-top: 15px;
      font-size: 16px;
    }
    &__title {
      font-size: 20px;
    }
  }
}
