@import "src/assets/style/utils/variables.module";

.dev-label {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  background: rgb(191, 199, 205);
  color: black;
  z-index: 10000;
  border-radius: 3px;
  font-size: 12px;

  @media (min-width: $breakpoint-md) {
    font-size: 14px;
    top: 0;
    bottom: auto;
  }
}