@import 'src/assets/style/utils/variables.module';

.ReferCompleteBar-outter {
  height: 20px;
  width: 100%;
  background-color: rgb(215, 223, 229);
  border-radius: 25px;
  margin-bottom: 15px;
}

.ReferCompleteBar-inner {
  height: 100%;
  background-color: rgb(36, 73, 159);
  border-radius: 25px;
}

.ReferStatics_contents {
  width: 100%;

  p {
    margin: 0 0 8px 0;
  }

  &-button {
    width: 8px;

    .ReferStaticsButton {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      font-size: 8px;
      margin-top: 8px;
    }
  }

  &-text {
    &_number {
      float: right;
    }
  }
}

.ReferStatics_completed {
  .ReferStaticsButton {
    background: #24499F;
  }
}

.ReferStatics_entered {
  .ReferStaticsButton {
    background: #D7DFE5;
  }
}

.ReferStatics_title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
}