@import 'src/assets/style/utils/variables.module';

.redemption-drawer-code,
.redemption-drawer-new {
  &__img {
    display: block;
    width: 86px;
    height: 53px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    font-size: $fontTitleXs;
    font-weight: bold;
    margin-top: 8px;
    text-align: left;
  }

  &__instruction {
    font-size: $fontSubtitleXs;
    margin-top: 24px;
    font-weight: bold;

    &__list {
      line-height: 1em;
      list-style-type: decimal;
      margin: 8px 0 0;
      padding-left: 5px;
      font-size: $fontTextXs;

      a:link,
      a:visited,
      a:link,
      a:visited {
        color: var(--link-color);
      }

      &__element {
        font-size: 10px;
      }
    }
  }

  &__action {
    text-align: left;
    margin-top: 24px;
    &__link {
      font-size: $fontSmallTextXs;
      color: var(--link-color);
      text-decoration: underline;
    }
  }

  &__copy {
    display: flex;
    margin: 24px auto 0;
    height: 45px;
    border: 1px solid #048569;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;

    &__input {
      flex: 7;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.46px;

      input,
      select:focus,
      textarea {
        font-size: $fontInput !important;
      }
    }

    &__btn {
      background: #048569;
      flex: 3;
      height: 100%;
      color: white;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
      border-radius: 0 7px 7px 0;

      &:hover {
        background: #048569;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &__img {
      width: 112px;
      height: 69px;
      margin: 0;
    }
    &__title {
      margin-top: 16px;
      font-size: 24px;
      text-align: left;
    }
    &__instruction {
      font-size: 18px;

      &__list {
        line-height: 2em;
        padding-left: 20px;

        &__element {
          font-size: 16px;
        }
      }
    }

    &__action {
      margin-top: 30px;
      &__link {
        font-size: 16px;
      }
    }

    &__copy {
      max-width: 263px;
      height: 54px;
      margin: 24px 0 0;
      border: 2px solid var(--secondary);

      &__input {
        line-height: 18px;
        letter-spacing: 0.54px;
      }

      &__btn {
        font-size: 16px;
      }
    }
  }
  .maxw-100 {
    max-width: 100% !important;
  }

  &__timer {
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-size: 10px;
    font-weight: 600;

    &__ico {
      margin-right: 4px;
    }

    &__txt {
      font-size: 10px;
    }
  }
}

.MuiInputBase-input {
  text-align: center;
  font-size: 12px;
}

.custom-padding {
  padding: 31px 25px;
  padding-top: 15px;
}

.redemption-drawer-new {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  @media (max-width: $breakpoint-md) {
    height: 100vh;
    box-sizing: border-box;
    .redemption-drawer-code__copy {
      margin: 24px 0 0;
    }
  }
  .redemption-drawer-new-img {
    height: 30%;
    min-height: 229px;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    @media (max-width: $breakpoint-md) {
      height: 20vh;
    }
  }
  .img-updated {
    min-height: 20vh !important;
  }
  .redemption-drawer-new-details {
    padding: 1em;
    overflow: auto;
    min-height: 60%;
    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      height: 89%;
      padding-top: 0;
      @media (min-height: 980px) {
        height: 90% !important;
      }
    }
  }
  .code-drawer-updated {
    padding: 0 !important;
    overflow: auto;
  }
  .custom-padding-updated {
    padding: 31px 25px !important;
    padding-top: 0px !important;
  }
  .action-updated {
    margin-top: 20px;
  }
  .redemption-drawer-new__btn {
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    bottom: 25px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    @media (max-width: $breakpoint-md) {
      position: inherit;
      width: 100%;
      padding: 0;
      margin: 1em auto;
      .btn {
        height: 3em;
      }
    }
  }
  .btn-updated {
    position: inherit;
    padding: 0 !important;
  }
  .base-drawer__content__no-padding {
    margin-top: 0 !important;
    height: 100%;
    overflow-y: hidden;
  }
  .base-drawer__title__wrapper {
    padding: 18px 16px !important;
  }
  .redemption-drawer-new__content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .redemption-drawer-new__info {
    overflow: auto;
    font-size: 16px;
    .redemption-drawer-code__instruction__list ol {
      padding-inline-start: 1em !important;
    }
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      flex: 2;
      .header-container {
        display: flex;
        align-items: center;
        .image-container {
          margin-right: 10px;
        }
        .image-container img {
          width: 110px;
          height: auto;
          border-radius: 8px;
        }
        .text-container {
          align-self: flex-start;
        }
        .mt-0 {
          margin-top: 0 !important;
        }
      }
    }
  }
  .info-updated {
    overflow: auto;
    max-height: 30vh;
  }
  .text-bottom-bumper {
    display: inline-block;
    width: 100%;
    margin-top: 60px;
  }
  .redemption-drawer-new__title {
    color: black;
    font-size: 18px;
    font-weight: 700;
    @media (min-width: $breakpoint-md) {
      margin-top: 1.25em;
    }
  }
  .redemption-drawer-new__subtitle {
    text-transform: uppercase;
    color: #3E5656;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.25em;
    @media (min-width: $breakpoint-md) {
      margin-bottom: -1em;
      margin-top: 1.25em;
    }
  }

  .redemption-drawer-new__timer {
    margin-top: 10px;
  }
  .redemption-drawer-code__instruction__list p {
    line-height: 20px;
  }
  .redemption-drawer-code__action__link {
    font-size: 14px;
  }
}

.redemption-drawer-code-unique {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #bfc7cd;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 24px;
  @media (min-width: $breakpoint-md) {
    margin: 22px auto 0;
    max-width: 263px;
    border: 2px solid #bfc7cd;
  }
  .MuiSvgIcon-root {
    font-size: 1.25em;
  }
  .code-row {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    @media (min-width: $breakpoint-md) {
      height: 54px;
    }
    .field-name {
      width: 40%;
      padding-left: 2.5%;
    }
    .field-value {
      width: 60%;
      display: flex;
      align-items: center;
      .code-input {
        line-height: 15px;
        letter-spacing: 0.46px;
        width: 90%;
        cursor: pointer;
        padding-right: 1%;
        .MuiInputBase-input {
          text-align: end;
          cursor: pointer;
          font-size: 14px !important;
        }
        @media (min-width: $breakpoint-md) {
          line-height: 18px;
          letter-spacing: 0.54px;
        }
      }
      .copy-btn {
        padding-left: 0;
        color: #bfc7cd !important;
        width: fit-content !important;
        min-width: 0;
        font-size: medium;
      }
      .apply-btn {
        padding-left: 5px !important;
        color: inherit !important;
      }
    }
  }
}
.divider {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
}
.align-left {
  text-align: left !important;
  align-self: flex-start !important
}
