@import 'src/assets/style/utils/variables.module';

.campaigns-container {
  display: flex;
  flex-direction: column;
  margin-top: -24px;
  gap: 20px;
  .campaigns-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 2em;
  }
  .campaigns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }

  .featured-campaign {
    width: 49%;
    height: 380px;
  }
  .featured-campaign-img {
    height: 239px !important;
  }

  .campaign {
    width: 95%;
    height: 290px;
  }
  .campaign-img {
    height: 130px;
  }

  @media (max-width: $breakpoint-md) {
    margin-top: -30px;
    .campaigns-title {
      margin-bottom: -10px;
    }

    .featured-campaign {
      width: 100%;
      height: 330px;
    }
    .featured-campaign-img {
      height: 200px !important;
    }

    .campaign {
      width: 48%;
    }
    .campaign-img {
      height: 130px;
    }
  }
}
