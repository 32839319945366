@import "src/assets/style/utils/variables.module";

.provider-disconnect-dialog {
  max-width: 220px;

  &__ico {
    display: block;
    margin: auto;
    font-size: 40px;
    color: var(--secondary);
  }

  &__msg {
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.38px;
    color:  #647588;
  }

  &__btn {
    margin-top: 15px;
  }

  &__link {
    display: block;
    margin: 25px auto -24px;
    font-size: $fontTextXs;
    font-weight: 600;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 320px;

    &__ico {
      margin-top: 30px;
      font-size: 44px;
    }

    &__msg {
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    &__link {
      margin-bottom: 25px;
      font-size: 14px;
    }
  }
}