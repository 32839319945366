@import "src/assets/style/utils/variables.module";

.provider {
  display: flex;
  justify-content: space-between;
  min-height: 58px;
  align-items: center;
  margin-top: 8px;
  background: #E1E7EC;
  border-radius: 9px;
  cursor: pointer;
  flex-wrap: wrap;

  &__header {
    display: flex;
    min-height: 20px;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 100%;
    border-radius: 9px 9px 0 0;
    font-size: $fontDescriptionXs;
    font-weight: bold;
    background: var(--disable);
    background: #06599E;
    color: white;

    span {
      margin-right: 16px;
    }
  }

  &__failed {
    display: flex;
    min-height: 20px;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 100%;
    border-radius: 9px 9px 0 0;
    font-size: $fontDescriptionXs;
    font-weight: bold;
    background: var(--disable);
    background: #E9503C;
    color: white;

    span {
      margin-right: 16px;
    }
  }

  &__description {
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    align-items: center;
    flex: 1 1;

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px;
      background-color: white;
      border-radius: 50%;

      &__img {
        width: 20px;
        height: 20px;
        margin: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        border-radius: 50%;
      }

    }

    &__text {
      padding-right: 10px;
      margin: 10px 0;
      &__title {
        font-size: $fontSmallTextXs;
        font-weight: 700;
      }

      &__subtitle {
        margin-top: 4px;
        font-size: $fontDescriptionXs;
        color: #4b5054;
      }
    }
  }

  &__link {
    margin-bottom: 10px;
    margin-left: 58px;
    flex-basis: 100%;
    font-size: $fontSmallTextXs;
    font-weight: 500;
    text-decoration: underline;
  }

  &__connection {
    margin-right: 16px;
  }

  @media (min-width: $breakpoint-md) {
    min-height: 81px;
    &__header {
      font-size: 13px;
      min-height: 32px;
    }

    &__link {
      margin-left: 78px;
      font-size: 16px;
    }

    &__description {
      &__logo {
        &__img {
          width: 34px;
          height: 34px;
        }
      }
      &__text {
        margin: 19px 0;
        &__title {
          font-size: 16px;
        }

        &__subtitle {
          margin-top: 8px;
          font-size: 14px;
          
        }
      }
    }
  }
  .require_disconnect_header{
    background-color: rgba(255, 0, 0, 0.781);
  }
}