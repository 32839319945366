.carousel{
  &-arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 79px;
    z-index: 1;
    min-width: unset;
    background: var(--accesory);

    &__next{
      right: 13px;
    }

    &__prev {
      left: 13px;
    }

    &:hover{
      background: var(--accesory);
    }

    &__hide {
      display: none;
    }
  }
}