@import "src/assets/style/utils/variables.module";

.welcome-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px -6px;

  &__icon {
    width: auto;
    height: auto;
  }

  &__title {
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    p { 
      max-height: 100px;
      overflow: scroll;
      margin: 0;     
    }
    ::-webkit-scrollbar-track {
      @media (min-width: $breakpoint-md) {
        background-color: transparent;
        display: none;
      }
    }
    ::-webkit-scrollbar {
      @media (min-width: $breakpoint-md) {
        background-color: transparent;
        display: none;
      }
    }
  }

  &__btn {
    margin-bottom: -14px;
    font-weight: bold;
  }

  @media (min-width: $breakpoint-md) {
    &__btn {
      margin-bottom: 0;
    }
  }
}