.instructions-box {
  .back-box {
    display: flex;
    align-items: center;
    padding-bottom: 0.15em;
    border-bottom: 0.1px solid black;
    width: fit-content;
    padding-top: 1em;
    margin-left: 0.5em;
    cursor: pointer;
    .back-arrow {
      font-size: 0.75em !important;
      margin-top: 0.2em !important;
      font-weight: 600 !important;
    }
    .back-btn {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .title {
    text-align: center;
    margin: 2em 15%;
    p {
      font-family: 'Lato';
      font-weight: 700 !important;
      line-height: normal;
      font-size: 20px;
    }
  }
  .instructions-img {
    display: flex;
    justify-content: center;
    img {
      width: 85%;
    }
  }
  .text-box {
    display: flex;
    justify-content: center;
    .ios-safari {
      font-size: 12px;
      background-color: black;
      color: white;
      text-align: center;
      width: 72%;
      border-radius: 8px;
      padding: 0.5em 2em;
      position: fixed;
      bottom: 2em;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px;
        border-color: black transparent transparent transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .icon {
      display: flex;
      align-items: center;
      color: white;
      padding: 0px 0px;
      border: 1px solid white;
      border-radius: 2px;
      display: inline-block;
      cursor: pointer;
      height: 0.65em;
      width: 0.75em;
      position: relative;
      bottom: -3px;
      margin-left: 0.2em;
      margin-right: 0.2em;
      margin-bottom: 0.15em;

      .arrow {
        width: 0;
        height: 0;
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-bottom: 3px solid white;
        position: absolute;
        display: inline-block;
        top: -6px;
        right: 1px;
      }

      .arrow:after {
        content: '';
        position: absolute;
        top: 4px;
        left: -1px;
        width: 1px;
        height: 7px;
        background-color: white;
      }
    }

    .ios-other {
      font-size: 13px;
      text-align: center;
      width: 72%;
      margin-top: 1em;
      .icon {
        color: black;
        border: 1px solid black;
      }
      .arrow {
        border-bottom: 3px solid black;
      }
      .arrow:after {
        background-color: black;
      }
      .MuiSvgIcon-root {
        font-size: 2rem;
        width: 0.5em;
        height: 0.4em;
      }
      .ml-05 {
        margin-left: 0.5em;
      }
    }

    .os-other {
      font-size: 13px;
      text-align: center;
      width: 72%;
      margin-top: 1em;
    }
  }
}
