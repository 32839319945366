@import "src/assets/style/utils/variables.module";

.phone-verification-code {
  &__content {
    display: flex;

    &__description {
      &__title {
        font-weight: 700;
        font-size: 14px;
      }

      &__subtitle {
        margin-top: 8px;
        font-size: $fontTextXs;
        color: #3E5656;
      }
    }
  }

  &__form-control-code {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &__resend-code,
    &__resend-code--disabled {
      display: inline-flex;
      margin-top: 32px;
      font-size: $fontTextXs;
      text-decoration: underline;
      font-weight: bold;
    }
    &__resend-code {
      color: var(--link-color);
      cursor: pointer;
    }
    &__resend-code--disabled {
      color: #BFC7CD;
    }

    .pincode-input-text {
      width: 37px !important;
      height: 48px !important;
      margin-right: 8px !important;
      border: 2px solid #bfc7cd !important;
      border-radius: 3px;
      font-size: 16px;
      transition: none;

      &:focus {
        border-color: #647588 !important;
        outline: none;
      }
    }

    &__err-msg {
      margin-top: 8px;
      font-size: 10px;
      color: var(--error);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &__content {
      &__title {
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 700;
      }
    }

    &__form-control-code {
      margin-top: 39px;

      &__resend-code,
      &__resend-code--disabled {
        font-size: 14px;
      }

      .pincode-input-text {
        font-size: 18px;
      }

      &__err-msg {
        font-size: 12px;
      }
    }
  }
}

