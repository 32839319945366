.base-drawer {
  &__link {
    display: block;
    width: 100%;
    padding: 20px 0;
    color: black;
    text-decoration: none;
    text-transform: none;

    &__ico {
      width: 18px;
      height: 18px;
    }

    &__txt {
      margin-left: 15px;
      font-size: 14px;
      font-weight: bold;
    }

    &__chip {
      cursor: pointer;
    }
  }
}

.text__width {
  width: 100%;
  font-size: 14px;
}

.referralIcon {
  transform: scaleX(-1);
  margin-left: 2px;
  margin-right: -1px;
}
.base-drawer__link__txt {
  text-align: left;
}
.language_drawer {
  position: relative;
  .current_langauge {
    position: absolute;
    right: 20px;
  }
}

.user-id-copy-input {
  text-align: center !important;
}
