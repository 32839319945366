.invite-code-field {
  margin-bottom: 2em;
  width: 100% !important;
}

.opt-invite-code-toggle {
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
  align-items: center;
  gap: 0.5em;
  .icon-box {
    height: 3em;
    width: 3em;
    background-color: white;
    border-radius: 5em;
    position: relative;
    svg {
      position: absolute;
      left: 0.5em;
      bottom: 0.5em;
    }
  }
  .text-box {
    margin-left: 1em;
    margin-top: 0.4em;
    .title {
      font-family: 'Lato';
      font-size: 14px;
      font-weight: 700;
      color: black;
    }
    .helper-text {
      font-family: 'Lato';
      font-weight: 400;
      font-size: 12px;
      color: #3E5656;
    }
  }
}

.sign-up-actions {
  margin-top: 2em;
  width: 100%;
  .link-text {
    color: var(--link-color);
    font-size: 14px;
    font-family: 'Lato';
    display: inline;
  }
  .text {
    color: #000000;
    font-size: 14px;
    font-family: 'Lato';
    margin-right: 0.25em;
    display: inline;
  }
  a {
    text-decoration: none;
  }
}

.btn-responsive {
  margin-left: 0;
  width: 100%;
}
