@import 'src/assets/style/utils/variables.module';

.favorite-offer-wrapper {
  position: relative;
  .favorite-offer-upper {
    position: relative;
    margin-top: 1em;
    .img-wrapper {
      float: left;
      margin-left: 9px;
      .updated-UI-icon {
        margin-right: 0 !important;
      }
    }
    .category-name {
      padding-left: 9px;
      font-size: 12px;
      &__desktop {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .favorite-list-item {
    font-family: 'Lato';
    background-color: white;
    box-shadow: 0px 4px 4px 0px #00000007;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.03);
    margin-bottom: -0.5em;
    justify-content: space-between;
    min-height: 5.5em;
  }
  .extend-height {
    min-height: 6.5em !important;
  }
  .favorite-main {
    display: flex;
    grid-gap: 2em !important;
    gap: 2em !important;
    padding-left: 10px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .offer-points {
      margin-right: 1.5em;
      border-radius: 1000px;
      padding: 6px 12px 4px 12px;
      font-size: 12px;
      font-weight: 500;
      color: white;
      min-width: 4em;
      text-align: center;
      min-height: 1.5em;
      width: fit-content;
      word-wrap: break-word;
      &__desktop {
        font-size: 16px;
        margin-top: 5px;
      }
    }

    .offer-info {
      max-width: 60%;
      .subheader {
        color: #4B5054;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        &__desktop {
          font-size: 14px;
        }
      }
      .title {
        color: black;
        font-size: 14px;
        font-weight: 700;
        margin-top: -0.5em !important;
        &__desktop {
          font-size: 20px;
        }
      }
      .mt-25 {
        margin-top: 1.25em !important;
      }
    }

    .available-background {
      background-color: var(--points-badge);
      color: var(--points-badge-text);
    }

    .unavailable-background {
      color: white;
      background-color: #677979;
    }
  }
  .favorite-offer-upper {
    width: 100% !important;
    .favorite-disabled {
      cursor: not-allowed;
      .unselected-favorite-icon {
        display: none !important;
      }
    }
  }
  .favorite-offer-lower {
    width: 100% !important;
  }
  .status {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    padding-top: 11px;
    justify-content: flex-end;
    grid-gap: 1em !important;

    .sold-out {
      @media (max-width: 537px) {
        width: 44px;
      }
      @media (max-width: 370px) {
        width: unset;
      }
      color: rgba(233, 80, 60, 1);
    }
    .unavailable {
      width: 40%;
      color: #677979;
    }
  }
  .favorite-icon {
    padding: 0 !important;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  .capitalize-text {
    text-transform: capitalize;
  }
}
