@import 'src/assets/style/utils/variables.module';

.highlight-header {
  width: 21em;
  margin: auto;

  &__base {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    grid-gap: 8px;
    background: #ffffff;
    border-radius: 8px;
    padding: 1% 3%;
    height: auto !important;
  }
  &__imgholder {
    width: 5em;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
  &__text {
    margin-left: 10px;
    width: 75%;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #3E5656;
  }
  &__last {
    margin-bottom: 3em;
  }
}
