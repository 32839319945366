@import 'src/assets/style/utils/variables.module';

.balance-mobile {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #edf2f4;
  padding-top: 2em;
  padding-bottom: 0;
  .balance-mobile-box {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    flex-grow: 1;
    text-align: center;
    color: black;
    display: flex;
    .balance-text-box {
      flex-grow: 1;
      margin-left: 0.75em;
      .text-helper {
        font-weight: 400;
      }
    }
    .balance-mobile-info {
      border-radius: 8px;
      cursor: pointer;
      display: inline;
      .balance-mobile-icon {
        padding: 0;
        background: #3E5656;
        color: white;
        border-radius: 8px;
        height: 24px;
        width: 24px;
        cursor: pointer !important;
        &:hover {
          background: #3E5656;
          color: white;
        }
      }
    }
  }
  .MuiSvgIcon-root {
    height: 14px;
    width: 14px;
  }
}
