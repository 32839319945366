@import 'src/assets/style/utils/variables.module';

.progress-upper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 10px;
  margin-top: 1.15em;
}

.progress-left-info {
  &-level {
    margin-left: -0.03em;
    color: #647588;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
}

.progress-right-info {
  display: flex;
  align-items: center;

  &-day-left {
    color: #647588;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
}

.progress-outer {
  height: 4px;
  width: 100%;
  background-color: rgb(215, 223, 229);
  border-radius: 25px;
  margin-top: 0.15em;
  margin-bottom: 2.5em;
}

.progress-inner {
  height: 100%;
  border-radius: 25px;
}
.progress-bar {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.circle {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 0.5vw;
  &__card {
    border-width: 1px;
    width: 1vw;
    @media (min-width: $breakpoint-md) {
      max-width: 21px;
    }
    aspect-ratio: 1/1;
  }
  .checkmark {
    position: absolute;
    transform: rotate(50deg) translate(-50%, -50%);
    left: 33%;
    top: 50%;
    height: 1.5vw;
    width: 1vw;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    &__card {
      height: 1.3vw;
      width: 0.5vw;
      left: 31%;
      top: 46%;
    }
    @media (min-width: $breakpoint-md) {
      &__card {
        width: 0.25vw;
        height: 0.75vw;
      }
    }
    @media (min-width: 1418px) {
      &__card {
        width: 5px;
        height: 10px;
      }
    }
  }
  .circle-number {
    color: #3E5656;
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    &__card {
      font-size: 10px;
    }

    @media (min-width: 480px) {
      &__card {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}
.target__card {
  font-size: 10px;
  margin: 0;
  margin-top: 5px;
}
.target__extra_css {
  font-size: 8px;
  @media (min-width: $breakpoint-md) {
    font-size: 14px;
  }
}
.progress-line {
  position: relative;
  height: 2px;
  width: 8vw;
  background: #bfc7cd;
  margin-top: 3vw;

  &__card {
    margin-top: 2vw;
    width: 3vw;
  }
  &__extra_css {
    width: 2vw !important;
  }
  @media (max-width: 480px) {
    height: 1px;
    &__card {
      margin-top: 1.85vw;
    }
  }
}
.campaign-drawer-details {
  @media (min-width: $breakpoint-md) {
    .progress-line {
      width: 20px;
      margin-top: 28px;
    }
    .circle .checkmark {
      height: 1vw;
      width: 0.5vw;
      left: 29%;
    }
  }
}
.updated-featured-campaigns .campaign-list-item {
  .progress-line {
    margin-top: 3vw;

    @media (max-width: 480px) {
      margin-top: 2.65vw;
    }
    @media (min-width: $breakpoint-md) {
      margin-top: 1.25vw !important;
      &__card {
        margin-top: 1vw;
      }
      &__extra_css {
        width: 1vw !important;
      }
    }

    @media (min-width: 1418px) {
      width: 16px !important;
      margin-top: 17px !important;
    }
  }
  .circle {
    max-width: unset !important;
  }
}
.updated-campaigns .campaign-list-item {
  .progress-line {
    @media (min-width: $breakpoint-md) {
      &__card {
        margin-top: 0.75vw;
      }
      &__extra_css {
        width: 0.75vw !important;
      }
    }
    @media (min-width: 1650px) {
      &__card {
        margin-top: 11px;
      }
      &__extra_css {
        max-width: 9px !important;
      }
    }
  }

  @media (min-width: $breakpoint-md) and (max-width: 1095px) {
    .circle {
      max-width: 20px;
    }
    .progress-line__extra_css {
      width: 4px !important;
    }
  }
}
.not-fulfilled {
  border: 2px solid #bfc7cd;
  @media (max-width: 480px) {
    border: 1px solid #bfc7cd;
  }
}
.fulfilled {
  background: #00b01d;
  border: 2px solid #00b01d;
}
