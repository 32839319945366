.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}

.height-full {
  height: 100%;
}
.relative {
  position: relative;
}
.pointer {
  cursor: pointer;
}

/* Spaces */
.h-margin-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.h-margin-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.h-margin-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-right-3 {
  margin-right: 3px;
}

.m-right-4 {
  margin-right: 4px;
}

.m-right-5 {
  margin-right: 5px;
}

.m-right-8 {
  margin-right: 8px;
}

.m-right-20 {
  margin-right: 20px;
}

.m-top-8 {
  margin-top: 8px;
}

.m-top-16 {
  margin-top: 16px;
}

.m-top-24{
  margin-top: 24px
}

.m-top-32 {
  margin-top: 32px;
}

.m-top-48 {
  margin-top: 48px;
}

.m-bottom-24 {
  margin-bottom: 24px;
}
.m-bottom-2 {
  margin-bottom: 2em;
}
/* /Spaces */


/* Fonts */
.t-center {
  text-align: center;
}

.f-size-10 {
  font-size: 10px;
}
.f-size-12 {
  font-size: 12px;
}
.f-size-14 {
  font-size: 14px;
}
.f-size-16 {
  font-size: 16px;
}
.f-size-18 {
  font-size: 18px;
}
.f-weight-bold {
  font-weight: bold;
}
.f-weight-600 {
  font-weight: 600;
}
/* /Fonts */
