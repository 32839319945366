.failed-attempts-left-dialog {
  .MuiDialog-paper {
    border-radius: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .info-icon {
    margin-right: auto;
    margin-left: auto;
    margin-top: 1em;
    font-size: 3rem;
  }
  .title {
    text-align: center;
    .MuiTypography-h6 {
      font-family: 'Lato';
      font-weight: 14px;
      font-weight: 700 !important;
    }
  }
  .content {
    text-align: center;
    color: #647588;
    padding: 4px 24px;
  }
  .dialog-btn {
    height: 3em;
    margin-right: 1em;
    margin-left: 1em;
    margin-top: 0.5em;
  }
  .close {
    position: absolute;
    left: 1em;
    top: 1em;
    color: #858585;
    cursor: pointer;
    margin-bottom: 1em;
  }
  .support-content {
    color: #647588;
    font-size: 14px;
    text-align: center;
    margin-bottom: 2em;
    margin-top: -0.25em;
    .highlight {
      font-size: 700;
      text-decoration: underline;
    }
  }
}