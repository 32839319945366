.MuiChip-colorPrimary {
  color: var(--primary-text);
  background: var(--primary);
}

.MuiChip-colorSecondary {
  color: var(--secondary-text);
  background: var(--secondary);
}

.MuiFormHelperText-root.Mui-error {
  color: var(--error);
}