@import "src/assets/style/utils/variables.module";

.campaign-drawer {
  overflow-x: auto;

  &__img {
    width: 100%;
  }
  &__info {
    margin-top: 18px;
    font-size: 12px;
    color: #4B5054 !important;

    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
  }

  &__available {
    margin-top: 16px;
    font-size: 12px;
    color: #F4AD07;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }
  &__details {
    display: flex;
    margin: 16px 0;
    flex-wrap: wrap;
  }

  &__ico{
    margin-right: 16px;
  }

  &__timer {
    margin-right: 12px;
    padding: 2px 0;
    font-size: 10px;
    display: flex;
    align-items: center;
  }

  &__cta {
    margin-top: 24px;

    .btn {
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
    }
  }


  @media (min-width: $breakpoint-md) {
    &__title {
      font-size: 24px;
    }

    &__timer {
      font-size: 14px;
    }

    &__details {
      margin: 33px 0 25px;
    }

    &__info {
      font-size: 16px;
    }

    &__status-ico {
      .badge {
        display: block;
        position: relative;
        top: auto;
        right: auto;
      }
    }

    &__cta {
      margin-top: 32px;
    }
  }
}
.wmde-markdown {
  font-family: 'Lato' !important;
  background-color: transparent !important;
  color: inherit;
  line-height: 1.35;
  font-size: inherit;
  ul, ol {
    margin-top: -2em;
    margin-bottom: -2em;
    li {
      margin-top: -1em;
    }
  }
}