@import "src/assets/style/utils/variables.module";

.offer-list-item-update-wrapper {
  position: relative;

  .favorite-icon {
    position: absolute;
    right: 0px;
    bottom: 10px;
    @media (min-width: $breakpoint-md) {    
      top: 10px;
    }
    .selected-favorite-icon {
      color: red;
    }
    .unselected-favorite-icon {
      color: black;
    }
  }

  .favorite-disabled {
    cursor: not-allowed;
    .unselected-favorite-icon {
      display: none !important;
    }
  }
}
.offer-list-item-updated {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  font-family: 'Lato';
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000007;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.03);
  margin-bottom: -0.5em;
  justify-content: space-between;
  min-height: 4.5em;

  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    .offer-points {
      margin-left: 1em;
      margin-right: 1em;
      border-radius: 1000px;
      padding: 6px 12px 4px 12px;
      font-size: 12px;
      font-weight: 500;
      color: white;
      min-width: 4em;
      text-align: center;
      min-height: 1.5em;
      word-wrap: break-word;
      &__desktop{
        font-size: 16px;
      }
    }

    .offer-info {
      .subheader {
        color: #4B5054;
        font-size: 10px;
        font-weight: 700;
        &__desktop {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
      .title {
        color: black;
        font-size: 14px;
        font-weight: 700;
        margin-top: -0.5em !important;
        &__desktop {
          font-size: 20px;
        }
      }
      .mt-0 {
        margin-top: 0 !important;
      }
    }

    .available-background {
      background-color: var(--points-badge);
      color: var(--points-badge-text)
    }

    .unavailable-background {
      color: white;
      background-color: #677979;
    }
  }
  .status {
    padding-right: 1em;
    font-size: 12px;
    font-weight: 600;
    .sold-out {
      color: rgb(186, 43, 24);
    }
    .unavailable {
      color: #677979;
    }
  }
  .pr-4 {
    padding-right: 4em !important;
  }
}
.available-cursor {
  cursor: pointer;
}

.blocked-cursor {
  cursor: not-allowed;
}
