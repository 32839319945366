@import 'src/assets/style/utils/variables.module';

.error-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  .error-content {
    max-width: 600px;
    width: 100%;

    .error-image {
      width: 100%;
      height: auto;
      margin: 0 auto 20px;
    }

    .error-title {
      font-size: 24px;
      margin-bottom: 0.5rem;
      font-weight: 700;
      padding: 0 25px;
    }

    .error-description {
      font-size: 14px;
      margin-bottom: 0;
      white-space: pre-wrap;
      padding: 0 50px;
    }
  }

  @media (min-width: $breakpoint-md) {
    .error-description {
      font-size: 16px;
    }
  }
}
