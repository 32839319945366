@import "src/assets/style/utils/variables.module";

.phone-verification-mobile {
  &__content {
    display: flex;

    &__description {

      &__title {
        font-weight: 700;
        font-size: 14px;
      }

      &__subtitle {
        margin-top: 8px;
        font-size: $fontTextXs;
        color: #3E5656;
      }
    }
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &__label {
      font-size: 12px;
      font-weight: 700;
    }

    &__field {
      margin-top: 8px;

      .MuiInputBase-input {
        font-size: 16px;
      }

      &__error-label {
        margin-top: 8px;
        margin-left: 0;
        font-size: 10px;
      }
    }

    &__btn-active {
      height: 45px;
      margin-top: 16px;
      background-color: var(--btn-primary);
      color: var(--btn-text);
      text-transform: capitalize;

      &:hover {
        background-color: var(--btn-hover);
      }

      &.btn-disable {
        background-color: var(--disable);
        color: var(--disable-text);
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &__content {
      display: block;

      &__img {
        width: 118px;
      }

      &__title {
        margin-top: 23px;
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 700;
      }

      &__description {
        &__title {
          font-size: 16px;
        }
        &__subtitle {
          font-size: 16px;
        }
      }
    }

    &__form-control {
      margin-top: 48px;

      &__label {
        font-size: 14px;
      }

      &__field {
        margin-top: 12px;

        .MuiInputBase-input {
          font-size: 18px;
        }

        &__error-label {
          font-size: 12px;
        }
      }

      &__btn-active {
        height: 54px;
        font-size: 16px;
      }
    }
  }
}



