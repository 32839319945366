.account-details-box {
  display: flex;
  flex-direction: column;
  gap: 1em;
  
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #191919;
    font-family: 'Lato';
  }
  .accounts-details-field {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .actions-box {
      display: flex;
      flex-direction: row;
      .MuiButton-root {
        font-family: 'Lato' !important;
      }
      .MuiButton-root.secondary-btn {
        text-transform: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
      }
      .btn-box {
        width: 40%;
        .MuiButton-root {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .actions-box-drawer-view {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      .MuiButton-root {
        font-family: 'Lato' !important;
      }
      .MuiButton-root.secondary-btn {
        text-transform: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
        align-self: flex-start;
      }
      .align-center {
        align-self: center !important;
      }
      .btn-box {
        .MuiButton-root {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .MuiFilledInput-root {
        background-color: #FFFFFF;
        border-radius: 8px;
        border: solid 2px #bfc7cd;
        border-width: 2px;
      }
    .MuiFilledInput-root.Mui-disabled {
      background-color: var(--disable);
    }
    .MuiFilledInput-underline::before {
      visibility: hidden;
    }

    .MuiFilledInput-underline::after {
      visibility: hidden;
    }
    .MuiFormLabel-root.Mui-disabled {
      color: var(--disable-text);
      font-family: 'Lato';
    }
    .MuiInputBase-input {
      text-align: start;
      font-size: 16px;
      font-family: 'Lato';
      font-weight: 700;
    }
    .MuiInputBase-input.Mui-disabled {
      color: var(--disable-text);
      }
    .MuiFilledInput-input {
      padding: 24px 12px 10px;
    }
    .space-between {
        justify-content: space-between;
    }
    .gap-0 {
        gap: .5em;
    }
    .label {
        color: #647588;
        font-family: 'Lato' !important;
    }
    .disabled {
      background-color: var(--disable) !important;
      color: var(--disable-text);
    }
  }
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: #647588 !important;
      @media (max-width: 500px) {
        font-size: large;
      }
    }
  }
  
}

.maxw-30 {
  max-width: 30em !important;
}
