@import 'src/assets/style/utils/variables.module';

.update-cards-container {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .update-cards {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    flex-wrap: wrap;
    margin-top: -10px;
    gap: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .update-cards::-webkit-scrollbar {
    display: none;
  }

  .update-card {
    height: 70%;
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    padding: 10px 15px;
    box-shadow: 0px 1px 4px 0px #0000001a;
    gap: 20px;
  }

  .update-card-single {
    width: 95%;
    @media (max-width: $breakpoint-md) {
      padding-right: 0 !important;
    }
  }

  .update-card-texts {
    width: 65%;
  }

  .update-card-image {
    width: 35%;
    border-radius: 8px;
    height: 80%;
  }

  .update-icon {
    margin-right: -0.75em;
    margin-top: 0.15em;
    @media (max-width: $breakpoint-md) {
      margin-top: 0.35em;
      svg {
        font-size: 20px !important;
      }
    }
  }

  .subheader1 {
    color: #4B5054;
    font-size: 13px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    margin-bottom: 10px;
    @media (min-width: $breakpoint-md) {
      font-size: 16px;
    }
  }

  .update-card-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .update-card-title-text {
    font-size: 16px;
    display: -webkit-box;
    font-weight: 700;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin-bottom: 10px;
    @media (min-width: $breakpoint-md) {
      font-size: 20px;
    }
  }

  .subheader2 {
    color: #647588;
    font-weight: 400;
    font-size: 11px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    @media (min-width: $breakpoint-md) {
      font-size: 14px;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin-top: 70px;
    margin-bottom: 0;
    .update-cards {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;
      overflow-x: unset;
      height: auto;
    }

    .update-card {
      width: 43%;
      min-height: 6em;
    }

    .update-card-single {
      width: 43%;
    }
  }

  .overflow-hid {
    overflow: hidden !important;
  }
  .w-95 {
    width: 95% !important;
  }
  .report {
    color: #e9503c !important;
  }
  .info {
    color: #f4ad07 !important;
  }
  .new-releases {
    color: #09ba70 !important;
  }
}