@import 'src/assets/style/utils/variables.module';

.navigation {
  height: 100%;
  display: flex;
  align-items: stretch;

  &-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 25px;
    align-self: center;
    color: var(--header-text);
    text-decoration: none;
    opacity: 0.9;
    font-size: 14px;

    &.active {
      opacity: 1;
      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 3px;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
      }
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
    &__desktop {
      font-size: 18px;
      &.active {
        font-weight: 700 !important;
      }
    }
  }
}
