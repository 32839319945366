@import "src/assets/style/utils/variables.module";

.connect-drawer{
  &__content {
    display: flex;
    align-items: flex-end;

    &__description {

      &__title {
        font-weight: 700;
        font-size: 14px;
      }

      &__subtitle {
        margin-top: 8px;
        font-size: $fontTextXs;
        color: #4B5054;
        font-weight: 500;
      }
    }

    &__img {
      width: 83px;
      height: 45px;
      margin-right: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &__label {
      font-size: 12px;
      font-weight: 700;
    }

    &__field {
      margin-top: 8px;

      .MuiInputBase-input {
        font-size: 16px;
      }

      &__error-label {
        margin-top: 8px;
        margin-left: 0;
        font-size: 10px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    &__btn-active {
      margin-top: 16px;
      text-transform: capitalize;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__content {
      display: block;

      &__img {
        width: 127px;
        height: 69px;
        margin-bottom: 16px;
      }

      &__description {
        &__title {
          margin-top: 15px;
          font-size: 24px;
          letter-spacing: 0.75px;
        }
        &__subtitle {
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }

    &__form-control {
      margin-top: 48px;

      &__label {
        font-size: 14px;
      }

      &__field {
        margin-top: 12px;

        .MuiInputBase-input {
          font-size: 18px;
        }

        &__error-label {
          font-size: 12px;
        }
      }
    }
  }
}



