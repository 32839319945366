@import "src/assets/style/utils/variables.module";

.switcher--default {
  &.MuiSwitch-root {
    padding: 6px;
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
  }

  .MuiSwitch-track {
    border-radius: 16px;
    background: #3E5656;
  }

  .Mui-checked + .MuiSwitch-thumb {
    margin-right: 2px;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #09BA70;
  }
}

.switcher--validate {
  .MuiSwitch-track {
    background: #bfc7cd;
  }
}