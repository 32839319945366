.progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2.5em;
}
.progress-bar-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.progress-bar-segment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  margin: 0 0.5px;
}
.progress-bar-segment {
  height: 4px;
  background-color: #d7dfe5;
  width: 100%;
  position: relative;
}
.progress-bar-segment-progress {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease;
}
.tier-info {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-top: 3px;
  flex-wrap: wrap;
  .MuiSvgIcon-root {
    font-size: 1rem;
    color: #ffa300;
  }
}
.tier-text {
  font-size: 12px;
  font-weight: 700;
  color: black;
}
.h-14 {
  height: 14px;
}
.br {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.bl {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.align-end {
  align-self: flex-end;
}
