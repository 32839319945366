@import "src/assets/style/utils/variables.module";

.featured__preview__title {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  font-size: $fontTitleXs;
  font-weight: bold;

  @media (min-width: $breakpoint-md) {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }
}