.button {
  background-color: var(--btn-primary);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
}
.button:hover {
  background-color: var(--btn-hover);;
}
.button-label {
  color: var(--btn-text);
}
.home-spinner-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  .spinner-holder {
    width: 100%;
    height: 140px;
  }
}