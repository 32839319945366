html {
  font-size: 16px;
}

body {
  margin: 0;
}

body,
body button {
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* react-simple-pull-to-refresh */
.ptr, .ptr .ptr__children {
  overflow: unset !important;
}
/* /react-simple-pull-to-refresh */