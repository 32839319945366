$breakpoint-xs: 330px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$main-content-max-width: 1112px;
$main-content-padding-xs: 15px;
$main-content-padding-md: 160px;

/*Fonts*/
$fontDescriptionXs: 10px;
$fontSmallTextXs: 12px;
$fontTextXs: 14px;
$fontSubtitleXs: 16px;
$fontTitleXs: 18px;
$fontLargeTitleXs: 24px;

// $fontInput value should be exactly 16px cause of IOS' zoom issue
$fontInput: 16px;
/*/Fonts*/

:export {
  breakpoint-xs: $breakpoint-xs;
  breakpoint-sm: $breakpoint-sm;
  breakpoint-md: $breakpoint-md;
  breakpoint-lg: $breakpoint-lg;
  breakpoint-xl: $breakpoint-xl;
}