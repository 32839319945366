.StreakProgress-upper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 10px;
}

.StreakProgress-left-info {
  display: flex;
  justify-content: flex-start;

  &-complete {
    margin-top: 0px;
    margin-bottom: 6px;
    color: black;
  }
  &-level {
    margin-top: 0px;
    margin-bottom: 6px;
    margin-left: 4px;
    color: #647588;
  }
}

.StreakProgress-right-info {
  display: flex;
  align-items: center;

  &-dayleft {
    margin-top: 0px;
    margin-bottom: 6px;
    color: #3E5656;
  }

  &-timeicon {
    margin-top: 0px;
    margin-bottom: 5.7px;
    color: #BFC7CD;
    margin-left: 5.33px;
    width: 14px;
    height: 14px;
  }
}

.StreakProgress-outer {
  height: 4px;
  width: 100%;
  background-color: rgb(215, 223, 229);
  border-radius: 25px;
  margin-bottom: 15px;
}

.StreakProgress-inner {
  height: 100%;
  background-color: #09BA70;
  border-radius: 25px;
}