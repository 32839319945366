@import "src/assets/style/utils/variables.module";

.chip,
.chip-secondary--light {
  height: auto;
  padding: 4px 0;
  border-radius: 15px;
  font-size: $fontDescriptionXs;
  font-weight: bold;
}

.chip {
  &-secondary--light {
    color: #048569;
    background: lighter(#B0E8C0, 0.5);

    &.MuiChip-clickable:hover,
    &.MuiChip-clickable:focus {
      background: lighter(#B0E8C0, 0.6);
    }
  }
}