@import "src/assets/style/utils/variables.module";

.phone-verification-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    margin: 0 0 24px;
  }

  &__title {
    font-size: $fontTextXs;
    text-align: center;
  }

  &__btn {
    margin-top: 16px;
    background-color: var(--btn-primary);
    color: var(--btn-text);;
    width: 219px;
    height: 45px;

    &:hover {
      background-color: var(--btn-hover);
    }
  }

  @media (min-width: $breakpoint-md) {
    &__title {
      font-size: 16px;
    }
  }
}