@import 'src/assets/style/utils/variables.module';

.update-card-drawer-updated {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  .icon-updated {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .update-card-drawer-img {
    height: 30%;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
  }
  .updated-img {
    min-height: 200px !important;
  }
  .update-card-drawer-details {
    padding: 1em;
    overflow: auto;
    min-height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .subheader-1 {
      text-transform: uppercase;
      color: #4B5054;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0.5em;
    }
    .title {
      color: black;
      font-size: 18px;
      font-weight: 700;
    }
    .points {
      margin-top: 1em;
      width: fit-content;
      border-radius: 1000px;
      padding: 6px 10px 4px 10px;
      font-size: 14px;
      font-weight: 600;
      color: var(--points-badge-text);
      min-width: 4em;
      text-align: center;
      min-height: 1.5em;
      background-color: var(--points-badge);
    }
    .locked-background {
      color: white;
      background-color: #677979;
    }
    .description {
      font-size: 16px;
      .wmde-markdown {
        margin: 1em 0;
        margin-top: .5em;
      }
    }
    .description-updated {
      margin-bottom: -1em;
    }
    .unavailable-update-card-text {
      color: red;
      font-weight: 700;
      .info {
        font-weight: 400;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }
  .cta {
    margin: 1em;
    margin-top: 0;
    .btn {
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
    }
  }
  .cta-updated {
    margin: 1em 2em;
    margin-top: 0;
  }
  .update-card-drawer__timer {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progress-upper {
    font-size: 14px;
  }
  .progress-outer {
    height: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .progress-wrapper {
    margin-top: 20px;
  }
  .p-2 {
    padding: 1.5em 2em;
  }
  .flex-2 {
    flex: 2;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
}

.maxh-75 {
  max-height: 68vh;
}
