@import 'src/assets/style/utils/variables.module';

.campaign-list-category {
  margin-top: 24px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }

  &__ico {
    width: 18px;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    margin: 0;
  }

  @media (max-width: $breakpoint-md) {
    &__title {
      font-size: $fontTitleXs;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .category-campaign {
      width: 96%;
      height: 300px;
    }
    .category-campaign-img {
      height: 133px !important;
    }
  }
}
