@import "src/assets/style/utils/variables.module";

.skeleton-txt,
.skeleton-txt--default {
  border-radius: 4px;
}

.skeleton-txt--default {
  min-height: 15px;

  @media (min-width: $breakpoint-md) {
    min-height: 22px;
  }
}
