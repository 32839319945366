@import 'src/assets/style/utils/variables.module';

.offer-list {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &--loading {
    margin-top: 33px;

    &__description {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        width: 48%;
        margin-top: 15px;

        &__img {
          height: 108px;
        }
      }
    }
  }
  @media (min-width: $breakpoint-md) {
    margin-top: 32px;
    &__wrapper {
      margin: 0 -10px;
    }
    &--loading {
      margin-top: 20px;
      &__prompt {
        width: 20%;
      }
      &__description {
        justify-content: flex-start;
        &__item {
          flex-basis: 265px;
          margin: 15px 9px 15px 0;
          &__img {
            height: 158px;
          }
        }
      }
    }
  }
}
.offer-search {
  form {
    position: relative;
    height: 44px;
    width: 100%;
  }
  input,
  .searchBar {
    height: 28px;
    border-radius: 8px;
    border: 1px solid #bfc7cd;
    width: calc(100% - 10px);
    padding-left: 10px;
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .searchBtn {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    box-sizing: border-box;
    top: 5%;
    right: -8px;
  }
  .searchClearBtn {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    box-sizing: border-box;
    top: 12%;
    right: 25px;
    display: none;
    &--active {
      display: inline-block;
    }
  }
  .searchBtn .MuiSvgIcon-root {
    font-size: 25px;
  }
  .searchClearBtn .MuiSvgIcon-root {
    border-radius: 100%;
    background: var(--btn-primary);
    color: var(--btn-text);
    padding: 2px;
    font-size: 15px;
  }
  #search-clear {
    padding: 2px;
  }
}
.noResult-wrapper {
  text-align: center;
  margin-top: 13%;
  .noResult-text {
    p {
      margin: 0 0 7px 0px;
    }
    .top {
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
    .middle {
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #ffaa08;
    }
    .bottom {
      font-weight: 400;
      font-size: 19px;
      line-height: 23px;
      margin: 10px;
    }
  }
  button {
    width: 200px;
    background: var(--btn-primary);
    border: 1px solid var(--btn-primary);
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    height: 48px;
    color: var(--btn-text);
    margin: 15px 0 5px 0;
  }
}
.offer-list-cover {
  .mt-1 {
    margin-top: 1em;
  }
  .custom-search {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
  }
  .custom-icon {
    color: black;
    top: 10%;
    right: 0%;
    .MuiSvgIcon-root {
      font-size: 28px !important;
    }
  }
  &__desktop {
    .offer-category-cover {
      display: flex;
      flex-wrap: wrap;

      .offer-wrapper {
        width: 32.5%;
      }
      .offer-wrapper:nth-of-type(3n-1) {
        padding: 0 1.25%;
      }
    }
  }
}

.category-skeleton-updated {
  background-color: rgba(237, 242, 244, 1) !important;
  margin-bottom: 1em;
  height: 1.5em !important;
}

.noResult_desktop {
  margin: 13px auto !important;
}
