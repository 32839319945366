@import 'src/assets/style/utils/variables.module';

.refer-drawer-code {
  margin-bottom: 50px;
  &__img {
    display: block;
    width: 86px;
    height: 53px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    font-size: $fontTitleXs;
    font-weight: bold;
    margin-top: 8px;
    text-align: left;
  }

  &__instruction {
    font-size: $fontSubtitleXs;
    margin-top: 24px;
    font-weight: bold;

    &__list {
      line-height: 1em;
      list-style-type: decimal;
      margin: 8px 0 0;
      padding-left: 5px;
      font-size: $fontTextXs;

      a:link,
      a:visited,
      a:link,
      a:visited {
        color: var(--link-color);
      }

      &__element {
        font-size: 10px;
      }
    }
  }

  &__action {
    text-align: left;
    margin-top: 0px;
    text-align: center;
    &__link {
      font-size: $fontSmallTextXs;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--link-color);
    }
    &__input {
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    .MuiSvgIcon-root {
      margin-right: 2px;
    }
  }

  &__or {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #647588;
    p {
      margin-bottom: 5px;
    }
  }

  &__copy {
    display: flex;
    margin: 24px auto 0;
    height: 45px;
    border: 1px solid #647588;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;

    &__input {
      flex: 7;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.46px;

      input,
      select:focus,
      textarea {
        font-size: $fontInput !important;
      }
    }

    &__btn {
      color: var(--btn-text);
      background: var(--btn-primary);
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
      margin: 2px;
      border-radius: 7px;
      padding: 8px 26px;

      &:hover {
        background: var(--btn-hover);
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &__img {
      width: 112px;
      height: 69px;
      margin: 0;
    }
    &__title {
      margin-top: 16px;
      font-size: 24px;
      text-align: left;
    }
    &__instruction {
      font-size: 18px;

      &__list {
        line-height: 2em;
        padding-left: 20px;

        &__element {
          font-size: 16px;
        }
      }
    }

    &__action {
      margin-top: 30px;
      &__link {
        font-size: 16px;
      }
    }

    &__copy {
      height: 54px;
      margin: 24px 0 0;
      border: 2px solid var(--secondary);

      &__input {
        line-height: 18px;
        letter-spacing: 0.54px;
      }

      &__btn {
        font-size: 16px;
      }
    }
  }
  .refer-copy-msg {
    width: 100%;
    text-align: center;
    line-height: 1em;
    color: #048569;
  }
}

.MuiInputBase-input {
  text-align: center;
  font-size: 12px;
}
