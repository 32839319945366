@import 'src/assets/style/utils/variables.module';

.ReferHowTo-title {
  margin-bottom: 16px;
  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

.ReferHowTo-list {
  &_wrapper {
    td {
      vertical-align: top;
    }
    .HowToStep {
      border-radius: 50%;
      padding: 0.5em;
      background: #d7dfe5;
      text-align: center;
      width: 1em;
      line-height: 1em;
      font-size: 14px;
      color: #647588;
      margin: 0;
    }
    .HowToDescription {
      font-size: 14px;
      line-height: 20px;
      margin: 0px 0px 15px 8px;
      @media (min-width: $breakpoint-md) {
        font-size: 15px;
        margin-left: 4px;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.referral-instructions-desktop {
  background-color: #EAEAEA;
  border-radius: 16px;
  margin-top: 2em;
  padding: 2em 1em 1em 2em;
  ol {
    padding-inline-start: 20px;
    margin-block-start: .5em;
  }
}
