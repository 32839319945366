.default-link {
  color: var(--link-color);

  &a:link,
  & a:visited,
  a:link,
  a:visited {
    color: var(--link-color);
  }
}

.secondary-link {
  color: var(--secondary);

  &a:link,
  & a:visited,
  a:link,
  a:visited {
    color: var(--secondary);
  }
}

.terms-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--link-color);
  text-decoration: underline;
}