@import "src/assets/style/utils/variables.module";

.provider-list {
  &--loading__item {
    margin-top: 8px;
    min-height: 58px;
  }

  @media (min-width: $breakpoint-md) {
    &--loading__item {
      margin-top: 16px;
      min-height: 80px;
    }
  }
}