@import 'src/assets/style/utils/variables.module';

.invite-form {
  margin-top: 30px;
  &__label {
    font-size: 12px;
    font-weight: 700;
  }

  &__labelHint {
    font-size: 12px;
    padding-left: 2px;
  }

  &__input {
    margin-top: 8px;
    input,
    select:focus,
    textarea {
      font-size: $fontInput !important;
    }

    &.form-control .MuiInputBase-input {
      text-align: left;
      background-color: white;
    }

    &--fill {
      &.form-control .MuiInputBase-input {
        text-align: left;
        letter-spacing: 4px;
      }
    }

    &--fill-already {
      &.form-control .MuiInputBase-input {
        text-align: left;
        letter-spacing: 4px;
        background-color: #edf2f4;
      }
    }

    ::placeholder {
      position: absolute;
      pointer-events: none;
      left: 13px;
      top: 18px;
      font-weight: 400;
      font-size: 14px;
      color: #647588;
      letter-spacing: 0.01em;
      transition: 0.2s ease all;
      font-family: 'Lato';
      font-style: normal;
      opacity: 1;
    }
    .Mui-focused ::placeholder {
      top: 3px;
      font-weight: 400;
      font-size: 12px;
    }
  }
  &__btn {
    margin-top: 15px;
    &--loading {
      margin-top: 15px;
      padding: 13px 10px;
    }
  }

  @media (min-width: $breakpoint-md) {
    &__label {
      font-size: 14px;
    }

    &__input {
      &.form-control .MuiInputBase-input {
        font-size: 16px;
      }
    }
  }
}

.promo-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.list-label {
  font-size: 16px;
  font-weight: 700;
}

.add-new-code-button {
  text-transform: none !important;
  background-color: var(--btn-primary);
  width: 107px;
  color: var(--btn-text);
  font-size: 12px;
  border-radius: 8px;
  min-height: 24px;
  padding: 0.5em 0 0.5em 0;
  .MuiButton-label {
    line-height: 1em !important;
  }
}

.promo-codes {
  height: 35vh;
  overflow-y: scroll;
}

.promo-codes::-webkit-scrollbar {
  display: none;
}

.promotion-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #5E737D;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 5px;
}
.promotion-action {
  color: #a71e24;
}
.promotion-info {
  line-height: 5px;
  padding-left: 5px;
  &__upper {
    font-size: 14px;
    font-weight: 700;
    padding: 0;
  }
  &__below {
    font-size: 12px;
    color: #647588;
    font-weight: 400;
    padding: 0;
  }
}

.arrow-img {
  width: 16px;
  height: 16px;
  padding-right: 11px;
}

.add-new-promo-drawer {
  &__btn-active {
    height: 45px;
    margin-top: 16px;
    background-color: var(--btn-primary);
    color: var(--btn-text);
    text-transform: capitalize;

    &:hover {
      background-color: var(--btn-hover);
    }

    &.btn-disable {
      background-color: var(--disable);
      color: var(--disable-text);
    }
  }

  &__add-new-form {
    ::placeholder {
      position: absolute;
      pointer-events: none;
      left: 13px;
      top: 18px;
      font-weight: 400;
      font-size: 14px;
      color: #647588;
      letter-spacing: 0.01em;
      transition: 0.2s ease all;
      font-family: 'Lato';
      font-style: normal;
      opacity: 1;
    }
    .MuiInputBase-input {
      font-size: 14px;
      text-align: left;
      height: 56px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #647588;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-radius: 8px;
    }
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    margin-top: 0px;

    &__label {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    &__btn-active {
      height: 45px;
      margin-top: 16px;
      background-color: var(--btn-primary);
      color: var(--btn-text);
      text-transform: capitalize;

      &:hover {
        background-color: var(--btn-hover);
      }

      &.btn-disable {
        background-color: var(--disable);
        color: var(--disable-text);
      }
    }
  }
}

.promo-detail-drawer {
  &__title {
    font-size: 18px;
    font-weight: 600;
  }
  &__details {
    display: flex;
    margin: 16px 0;
    flex-wrap: wrap;
  }
  &__timer {
    margin-right: 12px;
    padding: 2px 0;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
  &__info {
    margin-top: 18px;
    font-size: 12px;
    color: #4B5054;

    &a:link,
    & a:visited,
    a:link,
    a:visited {
      color: var(--link-color);
    }
  }
}

.referral-detail-drawer {
  text-align: center;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  &__details {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #647588;
  }
  &__button {
    width: 100%;
    border-radius: 8px;
    color: var(--btn-text);
    background: var(--btn-primary);
    padding: 12px 32px;
  }
  .earnedPoints {
    color: #191919;
  }
}
