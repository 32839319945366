@import "src/assets/style/utils/variables.module";

.badge-top-left,
.badge-top-left--5,
.badge-top-left--10 {
  position: absolute;
  right: initial;
}

.badge-top-left {
  &--5 {
    top: 7px;
    left: 7px;
  }
  &--10 {
    top: 10px;
    left: 10px;
  }
}