@import "src/assets/style/utils/variables.module";

.balance__info {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #E1E7EC;
  border-radius: 15px;
  color: #191919;
  font-size: 12px;
  padding-right: 0;

  &__points {
    margin: 0 5px;
    font-size: 15px;
    font-weight: 900;
  }

  @media (min-width: $breakpoint-md) {
    background: transparent;
    color: var(--header-text);

    &__txt {
      font-size: 16px;
    }

    &__points {
      font-size: 20px;
      margin-left: 15px;
    }
  }
}