@import 'src/assets/style/utils/variables.module';

.updated-campaigns-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .updated-campaigns-title {
    display: flex;
    align-items: center;
    font-size: $fontTitleXs;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 2em;
  }
  .updated-campaign-details {
    .campaigns-info {
      margin-bottom: 0em;
    }
  }
  .updated-campaigns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
  }
  .updated-featured-campaigns {
    gap: 12px;
  }
  .updated-unfeatured-campaigns {
    gap: 9px;
  }

  .updated-featured-campaign {
    width: 32%;
    height: 360px;
  }
  .updated-featured-campaign-img {
    height: 200px;
  }

  .updated-campaign {
    width: 24%;
    height: 303px;
  }
  .updated-campaign-img {
    height: 130px;
  }

  @media (max-width: $breakpoint-md) {
    margin-top: -50px;
    .updated-campaigns-title {
      margin-bottom: -10px;
      font-size: $fontLargeTitleXs;
    }
    .updated-campaigns {
      flex-direction: column;
      gap: 0;
    }
    .updated-unfeatured-campaigns {
      flex-direction: row;
      justify-content: space-between;
    }

    .updated-featured-campaign {
      width: 100%;
      height: 165px;
      flex-direction: row;
      justify-content: space-between;
    }
    .updated-featured-campaign-img {
      height: 100%;
      width: 40%;
    }
    .updated-campaign-details {
      width: 70%;
      position: relative;
      .campaigns-info {
        width: 100%;
        margin-bottom: 0em;
      }
    }

    .updated-campaign {
      width: 48.5%;
    }
    .updated-campaign-img {
      height: 130px;
    }
    .h-200 {
      height: 200px;
    }
  }
}
