@import "src/assets/style/utils/variables.module";

.available-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin-top: 12px;
    font-size: $fontTitleXs;
    font-weight: 700;
  }

  &__time {
    font-size: $fontTextXs;
    margin: 14px 0 24px;
  }

  &__btn {
    background-color: var(--btn-primary);
    color: var(--btn-text);
    width: 219px;
    height: 45px;

    &:hover {
      background-color: var(--btn-hover);
    }
  }
}