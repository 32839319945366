@import "src/assets/style/utils/variables.module";
@import "src/assets/style/utils/mixins";

$bgColor: #E1E7EC;

.transaction-history {
  margin: 10px 0;
  background: lighter($bgColor, 0.4);
  border-radius: 10px;
  font-size: 12px;
  overflow: hidden;

  &__main {
    display: flex;
    flex-basis: 100%;
    padding: 10px 15px;
    justify-content: space-between;

    &__info {
      display: flex;
      position: relative;
      align-items: center;

      &__dot {
        position: absolute;
        top: calc(50% - 2.5px);
        left: -8px;
        width: 5px;
        height: 5px;
        background: #09BA70;
        border-radius: 50px;
      }

      &__circle {
        margin-right: 5px;
        padding: 10px;
        background: $bgColor;
        border-radius: 50%;
      }

      &__ico {
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }

      &__title {
        margin-bottom: 5px;
        font-weight: bold;
      }

      &__description {
        font-size: 10px;
        color: #4B5054;
      }
    }

    &__value {
      padding-left: 10px;
      align-self: center;
      font-size: 14px;
      white-space: nowrap;

      &__txt,
      &__txt--negative {
        font-weight: bold;
        margin-right: 0;
      }

      &__txt--negative {
        color: var(--error);
      }

      &__txt--linecross {
        font-weight: bold;
        text-decoration: line-through;
      }

      &__txt--neglinecross {
        color: var(--error);
        text-decoration: line-through;
      }

      &__txt--free {
        color: var(--secondary);
        text-transform: uppercase;
      }

      &__cta,
      &__cta.btn {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
      }
    }
  }

  &__coupon {
    margin-left: 63px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: $fontSmallTextXs;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--secondary);
  }

  @media (min-width: $breakpoint-md) {
    &__main {
      padding: 15px 30px;

      &__info {
        &__dot {
          top: calc(50% - 3.5px);
          left: -10px;
          width: 7px;
          height: 7px;
        }

        &__circle {
          margin-right: 10px;
        }

        &__title {
          font-size: 14px;
        }

        &__description {
          font-size: 14px;
        }
      }

      &__value {
        padding-left: 20px;

        &__txt,
        &__txt--negative {
          font-size: 16px;
        }

        &__cta,
        &__cta.btn {
          font-size: 13px;
        }
      }
    }

    &__coupon {
      margin-left: 84px;
      padding-bottom: 15px;
      font-size: 16px;
    }
  }
}
