@import 'src/assets/style/utils/variables.module';

.campaign-list--loading {
  &__category-title {
    margin-top: 33px;
    width: 25%;
  }

  &__description {
    display: flex;
    justify-content: space-between;

    &__item {
      width: 48%;
      margin-top: 9px;

      &__img {
        height: 108px;
      }

      &__title {
        width: 25%;
        margin-top: 8px;
      }

      &__subtitle {
        width: 65%;
        margin-top: 8px;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &__category-title {
      width: 20%;
    }

    &__description {
      &__item {
        width: 30%;

        &__img {
          height: 158px;
        }
      }
    }
  }
}
