.prompt-dialog {
  .MuiDialog-paper {
    border-radius: 1em;
    margin-top: 0;
    margin-bottom: 0;
    width: 65%;
    padding: 1em;
  }
  .MuiDialogTitle-root {
    padding: 10px 24px;
    padding-bottom: 0;
  }
  .title {
    text-align: center;
    .MuiTypography-h6 {
      font-family: 'Lato';
      font-weight: 700 !important;
      line-height: normal;
    }
  }
  .MuiDialogContent-root {
    padding: 8px 15px;
  }
  .content {
    .prompt-img {
      margin-top: 1em;
      margin-bottom: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 90%;
      }
    }
    p {
      font-family: 'Lato';
      font-size: 12px;
      text-align: center;
    }
  }
  .actions {
    .dialog-btn {
      height: 3em;
      color: white;
      cursor: pointer;
    }
    .not-now {
      background-color: #979797;
    }
    .add {
      background-color: #006ba6;
    }
  }
}
