@import 'src/assets/style/utils/mixins';
@import 'src/assets/style/utils/variables.module';

.balance-desktop {
  position: sticky;
  display: flex;
  top: 52px;
  z-index: 100;
  margin: 0 -15px;
  padding: 15px;
  align-items: stretch;
  justify-content: space-between;
  background: white;

  &--loading {
    &__info {
      max-width: 100%;
      width: 100%;
      height: 48px;
    }
  }

  &-history {
    background: #191919;
    color: white;
    border-radius: 15px;

    &:hover,
    &:focus {
      background: lighter(#191919, 0.2);
    }
  }

  @media (min-width: $breakpoint-md) {
    margin: 20px 0;
    padding: 15px 0;
    align-items: center;
    background: transparent;

    span {
      margin: 0 5px;
    }

    &-history {
      background: var(--btn-primary);
      color: var(--btn-text);

      &:hover,
      &:focus {
        background: var(--btn-hover);
      }
    }
  }

  @media (min-width: $breakpoint-md) and (max-width: 1024px) {
    padding: 15px 0;
  }

  .pl-0 {
    padding-left: 0 !important;
  }
}
.balance-desktop__new {
  border-radius: 1000px;
  padding: 2.5px 4px;
  font-size: 16px !important;
  font-weight: 400;
  min-width: 4em;
  text-align: center;
  min-height: 1.5em;
  background: var(--points-badge);
  color: var(--points-badge-text);
  cursor: pointer;
  .balance-desktop-history {
    padding: 0;
    background: var(--points-badge);
    color: var(--points-badge-text);
  }
  .balance__info {
    color: var(--points-badge-text);
    padding: 0;
    margin: 0px !important;
    font-size: 16px !important;
  }
  .balance__info__points {
    margin: 0px !important;
    font-size: 16px !important;
  }
}
